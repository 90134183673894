import React, { useState } from "react";
import { Button, Toast as toast, Card, Container, TypoGraph } from "components";

import {
  IDisbursementAccountResponse,
  IUpdateDisbursementAccountRequest,
} from "interfaces";
import strings from "strings";
import SignatureCanvas from "react-signature-canvas";
import { useNavigate } from "react-router";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { DialogTitle } from "@mui/material";
import { updateDisbursementAccount } from "api";

interface IDisbursementAccountData {
  disbursementAccount: IDisbursementAccountResponse | undefined;
}

const SignatureDialog: React.FC<IDisbursementAccountData> = (props) => {
  const { disbursementAccount } = props;

  const navigate = useNavigate();
  let sigCanvas: SignatureCanvas | null;
  const canvasHeight = 200;
  const canvasWidth = 550;
  const [loading, setLoading] = useState(false);

  function handleSave() {
    if (!sigCanvas || sigCanvas?.isEmpty()) {
      toast({ type: "warning", title: "Signature Required" });
      return;
    }
    if (!sigCanvas?.isEmpty() && disbursementAccount) {
      setLoading(true);
      const depositOptions = disbursementAccount.depositOptions.map(
        (option) => ({
          ...option,
          active: true,
        })
      );

      const payload: IUpdateDisbursementAccountRequest = {
        name: disbursementAccount.name,
        signatureImageBase64: sigCanvas?.toDataURL(),
        dualSignatureRequired:
          disbursementAccount.dualSignatureRequired &&
          disbursementAccount.approvalRequired,
        approvalRequired: disbursementAccount.approvalRequired,
        initialCheckNumber: disbursementAccount.initialCheckNumber,
        depositOptions: depositOptions,
      };

      updateDisbursementAccount(
        disbursementAccount?.companyId,
        disbursementAccount?.id,
        payload
      )
        .then((response) => {
          toast({
            title: strings.UPDATED_SUCCESSFULLY,
            subTitle:
              strings.DISBURSEMENT_ACCOUNT_STRING.DISBURSEMENT_ACCOUNT_WITH_ID +
              response.data.id,
          });
          navigate(`/settings/disbursementAccounts`);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  return (
    <>
      <Dialog open={true} maxWidth={"lg"} disableEscapeKeyDown>
        <DialogTitle id="alert-dialog-title">
          {"Signature Creation"}
        </DialogTitle>

        <DialogContent>
          <Container>
            <TypoGraph
              content={`Create signature for: ${disbursementAccount?.name}`}
            ></TypoGraph>
            <Card sxContainer={{ marginTop: "1rem", marginBottom: "1rem" }}>
              <SignatureCanvas
                penColor="black"
                ref={(ref) => {
                  sigCanvas = ref;
                }}
                canvasProps={{
                  width: canvasWidth,
                  height: canvasHeight,
                  className: "sigCanvas",
                }}
              />
            </Card>
            <Button
              variant="outlined"
              label="Clear"
              onClick={() => {
                sigCanvas?.clear();
              }}
            />
          </Container>
        </DialogContent>
        <DialogActions>
          <Container
            sx={{
              gridTemplateColumns: "auto auto",
              justifyContent: "end",
              gridGap: "1rem",
              padding: "12px 20px",
            }}
          >
            <Button
              id={"Save"}
              onClick={() => {
                handleSave();
              }}
              loading={loading}
              label={"Save Signature"}
            />
          </Container>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SignatureDialog;
