import React, { useCallback, useEffect, useState } from "react";
import {
  Blade,
  Button,
  Card,
  Container,
  Divider,
  HotjarSuppressor,
  Icon,
  Loader,
  SubmitDialog,
  SwitchCustomComp,
  Toast as toast,
  TypoGraph,
} from "components";
import styles from "./disbursementAccountBlade.module.scss";
import strings from "strings";
import { useNavigate } from "react-router";
import { FormProvider, useForm } from "react-hook-form";
import {
  CheckboxFormField,
  DropdownFormField,
  InputFormField,
} from "components/hookForm";
import SignatureCanvas from "react-signature-canvas";
import {
  deactivateDisbursementAccount,
  getCompaniesForUser,
  getDisbursementAccountsDepositOptions,
  getDisbursementAccountSignature,
  updateDisbursementAccount,
} from "api";

import {
  IDisbursementAccountResponse,
  IUpdateDisbursementAccountRequest,
} from "interfaces/IDisbursementAccountModel";
import { DropdownOptions, IDisbursementDepositOption } from "interfaces";
import { Actions, Resources, userCan } from "tools/privilegeChecker";
import { LightTooltip } from "components/toolTip/lightTooltip";
import { Box } from "@mui/material";
import colors from "theme/colors";

interface IDisbursementAccountBladeForm {
  showBlade: boolean;
  disbursementAccountData: IDisbursementAccountResponse | undefined;
  handleClose: () => void;
  reloadList: () => void;
}

const DisbursementAccountBladeForm: React.FC<IDisbursementAccountBladeForm> = (
  props
) => {
  const {
    showBlade = false,
    disbursementAccountData,
    handleClose,
    reloadList,
  } = props;

  let sigCanvas: SignatureCanvas | null;

  const methods = useForm<IUpdateDisbursementAccountRequest>();
  const { handleSubmit, reset, watch, getValues, control, setValue } = methods;
  const [companiesOptions, setCompaniesOptions] = useState<DropdownOptions[]>();
  const [signature, setSignature] = useState<string | undefined>();
  const [inactivateDialog, setInactivateDialog] = useState<boolean>(false);
  const [requiresApproval, setRequiresApproval] = useState<boolean>(false);
  const requiresApprovalWatch = watch("approvalRequired");
  const [depositOptions, setDepositOptions] = useState<
    IDisbursementDepositOption[]
  >([]);
  const [depositOptionsRequired, setDepositOptionsRequired] =
    useState<boolean>(false);
  const navigate = useNavigate();

  const getCompanies = useCallback(() => {
    getCompaniesForUser().then((response) => {
      const companyOptions = response?.data?.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      });
      setCompaniesOptions(companyOptions);
    });
  }, []);

  const [submitBtnLoader, setSubmitBtnLoader] = useState<boolean>(false);
  const [bladeLoading, setBladeLoading] = useState<boolean>(false);

  const isEdit = disbursementAccountData ? true : false;
  const t = strings.DISBURSEMENT_ACCOUNT_STRING;
  useEffect(() => {
    getCompanies();
  }, [getCompanies]);

  useEffect(() => {
    (async () => {
      setBladeLoading(true);
      try {
        if (disbursementAccountData?.id) {
          const signature = await getDisbursementAccountSignature(
            disbursementAccountData?.id
          );
          setSignature(signature.data.imageBase64);
          const availableDepositOptions =
            await getDisbursementAccountsDepositOptions();
          setDepositOptions(availableDepositOptions.data);
        } else {
          setSignature(undefined);
        }
      } catch {
        handleClose();
      } finally {
        setBladeLoading(false);
      }
    })();
  }, [disbursementAccountData]);

  useEffect(() => {
    if (
      !disbursementAccountData?.depositOptions ||
      disbursementAccountData?.depositOptions.length === 0
    ) {
      setValue("depositOptions", []);
    } else {
      const depositOptionList = depositOptions.map((depositOption) => {
        depositOption.active = !!disbursementAccountData.depositOptions.find(
          (depositOptionFromCompany) =>
            depositOptionFromCompany.id === depositOption.id
        );
        return depositOption;
      });
      setValue("depositOptions", depositOptionList);
    }
  }, [depositOptions, disbursementAccountData]);

  const handleSelectAllDepositoptions = () => {
    depositOptions.forEach((_, index: number) => {
      setValue(`depositOptions.${index}.active`, true);
    });
  };

  const updateSubmit = (data: IUpdateDisbursementAccountRequest) => {
    setSubmitBtnLoader(true);
    const filteredDepositOptions = depositOptions.filter(
      (depositOption) => data?.depositOptions[depositOption.id]?.active
    );
    if (sigCanvas?.isEmpty()) {
      toast({
        title: "Signature is required.",
        type: "warning",
        autoClose: 1500,
      });
      setSubmitBtnLoader(false);
      return;
    }
    const payload: IUpdateDisbursementAccountRequest = {
      name: data.name,
      signatureImageBase64: !sigCanvas?.isEmpty()
        ? sigCanvas?.toDataURL()
        : null,
      dualSignatureRequired:
        data.dualSignatureRequired && data.approvalRequired,
      approvalRequired: data.approvalRequired,
      initialCheckNumber: data.initialCheckNumber,
      depositOptions: filteredDepositOptions,
    };
    const anyDepositMethodSelected = filteredDepositOptions.length > 0;
    setDepositOptionsRequired(!anyDepositMethodSelected);

    if (!anyDepositMethodSelected) {
      setSubmitBtnLoader(false);
      return;
    }
    updateDisbursementAccount(
      disbursementAccountData?.companyId,
      disbursementAccountData?.id,
      payload
    )
      .then((response) => {
        toast({
          title: strings.UPDATED_SUCCESSFULLY,
          subTitle:
            strings.DISBURSEMENT_ACCOUNT_STRING.DISBURSEMENT_ACCOUNT_WITH_ID +
            response.data.id,
        });
        handleClose();
        reloadList();
      })
      .finally(() => {
        setSubmitBtnLoader(false);
      });
  };

  const deactivateSubmit = () => {
    deactivateDisbursementAccount(
      disbursementAccountData?.companyId,
      disbursementAccountData?.id
    )
      .then((response) => {
        toast({
          title: strings.DEACTIVATED_SUCCESSFULLY,
          subTitle:
            strings.DISBURSEMENT_ACCOUNT_STRING.DISBURSEMENT_ACCOUNT_WITH_ID +
            response.data.id,
        });
        handleClose();
        reloadList();
      })
      .finally(() => {
        setInactivateDialog(false);
      });
  };

  useEffect(() => {
    if (isEdit) {
      const formValues = {
        name: disbursementAccountData?.name,
        dualSignatureRequired: disbursementAccountData?.dualSignatureRequired,
        approvalRequired: disbursementAccountData?.approvalRequired,
        initialCheckNumber: disbursementAccountData?.initialCheckNumber,
      };
      setRequiresApproval(!!formValues.approvalRequired);
      reset(formValues);
    } else {
      reset({});
    }
  }, [isEdit, reset, disbursementAccountData]);

  useEffect(() => {
    setRequiresApproval(requiresApprovalWatch);
  }, [requiresApprovalWatch]);

  const BladeContent = () => {
    const canvasHeight = 200;
    const canvasWidth = 550;
    return (
      <Container className={styles.bladeContainer}>
        <Loader loading={bladeLoading} />
        <FormProvider {...methods}>
          {!isEdit && (
            <>
              <DropdownFormField
                id={"companyId"}
                name="companyId"
                label={strings.COMPANY}
                options={companiesOptions}
                required
              />
              <p style={{ textAlign: "center" }}>
                Securely add disbursement accounts via Plaid{" "}
              </p>
              <Button
                id={strings.DISBURSEMENT_ACCOUNT_STRING.ASSOCIATE_BANK_ACCOUNT}
                variant="contained"
                label={
                  strings.DISBURSEMENT_ACCOUNT_STRING.ASSOCIATE_BANK_ACCOUNT
                }
                loading={submitBtnLoader}
                onClick={handleSubmit((data) =>
                  navigate(`/settings/disbursementAccounts/oauth`, {
                    state: data.companyId,
                  })
                )}
              />
              <br />
              <br />
              <Card sxContent={{ display: "grid", gridGap: "10px" }}>
                <p>
                  Learn more about Plaid and your data. Plaid makes it easy for
                  people to securely connect their financial accounts to the
                  fintech services like Bank Shot. Plaid supports over 12,000
                  financial institutions so to improve financial accessibility.
                  Once your financial account is connected, you will be able to
                  use Bank Shot to facilitate disbursements to anyone you
                  choose. To learn more about Plaid please visit:
                  https://plaid.com/how-we-handle-data/
                </p>
              </Card>
            </>
          )}

          {isEdit && (
            <>
              <InputFormField
                id={t.DISBURSEMENT_ACCOUNT_NAME}
                name="name"
                label={t.DISBURSEMENT_ACCOUNT_NAME}
                required
                maxLength={32}
              />
              <p style={{ textAlign: "center" }}>Signature</p>
              {!disbursementAccountData?.hasSignature && (
                <Container className={styles.warningMessage}>
                  <Icon name="icon_info" size={25} color={colors.dark} />
                  <TypoGraph
                    className={styles.textBlade}
                    content="This account has no signature, you can add it on the canvas below, remember that the signature is required to update and use the account."
                  />
                </Container>
              )}
              <Card>
                <HotjarSuppressor>
                  {signature ? (
                    <Icon
                      name="signature"
                      type="images"
                      url={`data:image/png;base64,${signature}`}
                      height={canvasHeight}
                      width={canvasWidth}
                    />
                  ) : (
                    <SignatureCanvas
                      penColor="black"
                      ref={(ref) => {
                        sigCanvas = ref;
                      }}
                      canvasProps={{
                        width: canvasWidth,
                        height: canvasHeight,
                        className: "sigCanvas",
                      }}
                    />
                  )}
                </HotjarSuppressor>

                <Button
                  variant="outlined"
                  label="Clear"
                  loading={submitBtnLoader}
                  onClick={() => {
                    sigCanvas?.clear();
                    setSignature(undefined);
                  }}
                />
                <Container className={styles.requiresApprovalContainer}>
                  <SwitchCustomComp
                    name="approvalRequired"
                    title={
                      strings.DISBURSEMENT_ACCOUNT_STRING
                        .REQUIRES_APPROVAL_TO_SUBMIT
                    }
                    caption={
                      strings.DISBURSEMENT_ACCOUNT_STRING
                        .REQUIRES_APPROVAL_TO_SUBMIT_CAPTION
                    }
                    style={styles.switchContainer}
                  />
                  {(requiresApprovalWatch || requiresApproval) && (
                    <SwitchCustomComp
                      name="dualSignatureRequired"
                      title={
                        strings.DISBURSEMENT_ACCOUNT_STRING
                          .DUAL_SIGNATURE_REQUIRED
                      }
                      caption={
                        strings.DISBURSEMENT_ACCOUNT_STRING
                          .DUAL_SIGNATURE_REQUIRED_CAPTION
                      }
                      style={styles.switchContainer}
                    />
                  )}
                </Container>
                <Container className={styles.initialCheckNumberContainer}>
                  <InputFormField
                    id={strings.INITIAL_CHECK_NUMBER}
                    name="initialCheckNumber"
                    label={strings.INITIAL_CHECK_NUMBER}
                    type={"number"}
                    defaultValue={getValues("initialCheckNumber")}
                    maxLength={32}
                  />
                  <LightTooltip
                    title={
                      getValues("initialCheckNumber")
                        ? `The Check number entered is the actual check number, the next disbursement will have the number 
                        ${
                          +getValues("initialCheckNumber") + 1
                        }, not ${getValues("initialCheckNumber")}.`
                        : "The check number entered is the actual check number. If nothing is entered, the check numbers for this account will auto generate in a sequential order."
                    }
                  >
                    <Box
                      sx={{
                        alignSelf: "center",
                        height: 30,
                      }}
                    >
                      <Icon name="icon_help" size={30}></Icon>
                    </Box>
                  </LightTooltip>
                </Container>
                <Divider title="Deposit Options" />
                <Container>
                  <Container className={styles.selectAllContainer}>
                    <TypoGraph
                      className={styles.textBlade}
                      content="Select the available options to deposit the disbursements for this account."
                    />

                    <Button
                      variant="text"
                      className={styles.selectButton}
                      label="Select All"
                      onClick={() => handleSelectAllDepositoptions()}
                    />
                  </Container>
                  {depositOptions.map((field, index) => {
                    return (
                      <Container key={`key-${field.id}`}>
                        <Container>
                          <CheckboxFormField
                            id={field.name}
                            name={`depositOptions.${field.id}.active`}
                            label={field.name}
                          />
                        </Container>
                      </Container>
                    );
                  })}
                  {depositOptionsRequired && (
                    <TypoGraph
                      variant="body2"
                      color={colors.error}
                      content="It is required to select at least one *"
                    ></TypoGraph>
                  )}
                </Container>
              </Card>
            </>
          )}
        </FormProvider>
      </Container>
    );
  };

  const BladeFooter = (props: { isVerifiable: boolean }) => {
    const { isVerifiable } = props;
    return (
      <Container className={styles.actionContainer}>
        {isEdit && (
          <>
            <Button
              id={strings.MAINTENANCE_REPORT}
              variant="outlined"
              label={strings.MAINTENANCE_REPORT}
              onClick={() => {
                navigate(`${disbursementAccountData?.id}/maintenance`);
              }}
            />
            <Button
              id={strings.UPDATE}
              variant="contained"
              label={strings.UPDATE}
              loading={submitBtnLoader}
              onClick={handleSubmit((data) => updateSubmit(data))}
            />
            {isVerifiable && (
              <Button
                id={strings.DISBURSEMENT_ACCOUNT_STRING.VERIFY}
                variant="outlined"
                label={strings.DISBURSEMENT_ACCOUNT_STRING.VERIFY}
                onClick={() =>
                  navigate(
                    `/settings/disbursementAccounts/verify?disbursementAccountId=${disbursementAccountData?.id}`
                  )
                }
              />
            )}
            {userCan(Actions.DELETE, Resources.DISBURSEMENT_ACCOUNTS) ? (
              <Button
                id={strings.DEACTIVATE}
                color="error"
                label={strings.DEACTIVATE}
                onClick={() => setInactivateDialog(true)}
              />
            ) : null}
          </>
        )}

        <Button
          id={strings.CANCEL}
          variant="outlined"
          label={strings.CANCEL}
          onClick={handleClose}
        />
        <SubmitDialog
          type="delete"
          title={strings.DISBURSEMENT_ACCOUNT_STRING.CONFIRM_DEACTIVATE}
          open={inactivateDialog}
          body1={`Are you sure you'd like to deactivate the account?`}
          body2={strings.DISBURSEMENT_ACCOUNT_STRING.CONFIRM_INACTIVATE_CAPTION}
          handleClose={() => setInactivateDialog(false)}
          primaryBtnProps={{
            id: strings.DEACTIVATE,
            label: strings.DEACTIVATE,
            onClick: deactivateSubmit,
            loading: false,
            color: "error",
          }}
        />
      </Container>
    );
  };

  return (
    <Blade
      show={showBlade}
      title={
        isEdit ? t.UPDATE_DISBURSEMENT_ACCOUNT : t.CREATE_DISBURSEMENT_ACCOUNT
      }
      handleClose={handleClose}
      content={<BladeContent />}
      footerContent={
        <BladeFooter
          isVerifiable={
            disbursementAccountData?.verificationStatus !== "Verified"
          }
        />
      }
      headerLine
    />
  );
};

export default DisbursementAccountBladeForm;
