import React, { useCallback, useEffect, useState } from "react";
import {
  Container,
  DataGrid,
  Icon,
  StepperComponent,
  TypoGraph,
} from "components";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import strings from "strings";
import { dialogFields } from "constants/itemUpdateActions";
import colors from "theme/colors";
import { DropdownFormField, InputFormField } from "components/hookForm";
import { FormProvider, useForm } from "react-hook-form";
import { DropdownOptions, IPayment } from "interfaces";
import Endorsement, { IStateProps } from "pages/completed/partial/endorsement";
import { IDropdownFieldProps } from "components/hookForm/dropdownFormField";
import styles from "./depositItemDialog.module.scss";
import { paymentDepositSumaryColumns } from "constants/tableData";
import {
  depositItem,
  submitACHItems,
  submitItems,
  submitPassportChecks,
  withdrawItemToExternalAccount,
} from "api";
import { paymentMethods } from "constants/paymentMethods";
import toast from "components/toast";
import { useNavigate } from "react-router";
import { fireworksConfetti } from "tools/confetti";
import CropEditor from "components/crop";
import { paymentStatus } from "constants/paymentStatus";
import { ISubmitCheckRequest } from "interfaces/IMultipleCheckDeposit";

interface IUpdateItemDialogProps {
  open: boolean;
  handleClose: () => void;
  fields: dialogFields;
  selectedPayment: IPayment;
}

const DepositItemDialog: React.FC<IUpdateItemDialogProps> = (props) => {
  const { open, handleClose, fields, selectedPayment } = props;
  const availableAccounts: DropdownOptions[] = fields.dropdown?.options || [];
  const endorsement = fields.endorsement;
  const [endorseRefresh, setEndorseRefresh] = useState<boolean>(false);
  const methods = useForm<any>();
  const { handleSubmit, reset, watch, getValues, setValue } = methods;
  const [activeStep, setActiveStep] = useState<number>(0);
  const [selectedAccountLabel, setSelectedAccountLabel] = useState<
    string | undefined | number
  >();
  const [selectedMethodLabel, setSelectedMethodLabel] = useState<
    string | undefined | number
  >();
  const [selectedAccountId, setSelectedAccountId] = useState<any>(
    selectedPayment?.accountId
  );

  const isPassportCheckCapture =
    selectedPayment?.statusId == paymentStatus.PASSPORT_RC_COMPLETED;

  const endorsable =
    (selectedPayment?.paymentMethodId === paymentMethods.CHECK_CAPTURE &&
      !isPassportCheckCapture) ||
    selectedPayment?.paymentMethodId === paymentMethods.DIGITAL_CHECK;

  const isACHTransfer =
    selectedPayment?.paymentMethodId === paymentMethods.ACH_TRANSFER;

  const endorsableDepositSteps = [
    "Select Bank Account",
    "Adjust Image",
    "Endorse",
    "Confirm Details",
    "Done",
  ];

  const depositStepsWithoutEndorsement = [
    "Select Bank Account",
    "Confirm Details",
    "Done",
  ];

  const validateSteps = [
    "Select Bank Account",
    "Adjust Image",
    "Confirm Details",
    "Done",
  ];

  const navigate = useNavigate();
  const [depositError, setDepositError] = useState<boolean>(false);
  const [submitError, setSubmitError] = useState<boolean>(false);
  const [submitRejected, setSubmitRejected] = useState<boolean>(false);
  const [loadingDeposit, setLoadingDeposit] = useState<boolean>(false);
  const [endorsedFlag, setEndorsedFlag] = useState<boolean>();
  const [endorsed, setEndorsed] = useState<boolean>();
  const [alreadyDeposited, setAlreadyDeposited] = useState<boolean>(false);
  const [responseData, setResponseData] = useState<any>();
  const [errorMessage, setErrorMessage] = useState({
    title: "",
    subTitle: "",
  });
  const [accountType, setAccountType] = useState<"deluxe" | "receivable">();
  const [steps, setSteps] = useState<string[]>(endorsableDepositSteps);

  const SUBMIT_REJECTED_ERROR =
    "Rejected: Please contact technical support for assistance.";
  const [availablePaymentMethods, setAvailablePaymentMethods] =
    useState<IDropdownFieldProps>({
      disabled: true,
      options: [],
      label: strings.DEPOSIT_AS,
      name: "paymentMethodId",
      required: true,
    });
  const [endorsementRequired, setEndorsementRequired] =
    useState<boolean>(false);
  const [imagesCropped, setImagesCropped] = useState<boolean>(false);
  const [checkIsRotated, setCheckIsRotated] = useState<boolean>(false);

  const [validateError, setValidateError] = useState<boolean>(false);
  const [alreadyValidating, setlreadyValidating] = useState<boolean>(false);

  const isDeluxeAccount = accountType === "deluxe";
  const isReceivableAccount = accountType === "receivable";

  useEffect(() => {
    if (selectedPayment) {
      reset({
        newStatusId: selectedPayment.statusId?.toString(),
        amountInDollars: selectedPayment.amount,
      });
    }
  }, [selectedPayment, reset]);

  useEffect(() => {
    if (activeStep == 2) {
      setEndorsed(endorsedFlag);
    }
  }, [endorsedFlag]);

  useEffect(() => {
    const accountId = getValues("accountId");
    setSelectedAccountId(accountId);
    let selectedAccount = availableAccounts?.find(
      (option) => option.value === accountId
    );

    setSelectedAccountLabel(selectedAccount?.label);

    if (selectedAccount?.accountType === "deluxe") {
      setSteps(
        endorsable ? endorsableDepositSteps : depositStepsWithoutEndorsement
      );
      setAccountType("deluxe");
      setValue("paymentMethodId", "0");
      setAvailablePaymentMethods({
        disabled: !accountId,
        options: [{ label: "Check Payment", value: "0" }],
        label: strings.DEPOSIT_AS,
        name: "paymentMethodId",
        required: true,
        defaultValue: "0",
        value: "0",
      });
      return;
    } else if (selectedAccount?.accountType === "receivable") {
      setSteps(endorsable ? validateSteps : depositStepsWithoutEndorsement);
      setAccountType("receivable");
      setValue("paymentMethodId", undefined);
      if (availableAccounts) {
        const options: DropdownOptions[] = availableAccounts;
        const selectedOption = options.find(
          (option: DropdownOptions) => option.value === accountId
        );

        if (selectedOption) {
          const paymentMethodsOptions =
            selectedOption.externalDepositPaymentMethods?.map((method: any) => {
              return {
                value: method.id,
                label: method.name,
              };
            });
          if (paymentMethodsOptions?.length === 1) {
            setValue("paymentMethodId", paymentMethodsOptions[0].value);
          }
          setAvailablePaymentMethods({
            disabled: paymentMethodsOptions?.length == 0,
            options: paymentMethodsOptions,
            label: strings.DEPOSIT_AS,
            name: "paymentMethodId",
            required: true,
          });
        }
      }
    }
  }, [watch("accountId")]);

  useEffect(() => {
    const methodId = getValues("paymentMethodId");
    setSelectedMethodLabel(
      availablePaymentMethods?.options?.find(
        (option) => option.value === methodId
      )?.label
    );
  }, [watch("paymentMethodId")]);

  const handleStepBack = () => {
    setEndorsedFlag(endorsement?.backImage?.endorsed ? true : false);
    if (isReceivableAccount && activeStep == 1) {
      setValidateError(false);
    }
    if (activeStep == 2) {
      setEndorsed(false);
    }
    if (activeStep == 2) {
      setDepositError(false);
    }
    setActiveStep(activeStep - 1);
  };

  const handleStepNext = async (formData: any) => {
    if (isReceivableAccount && endorsable) {
      switch (activeStep) {
        case 0:
          setActiveStep(1);
          break;
        case 1:
          setActiveStep(2);
          break;
        case 2:
          validateItem(formData);
          break;
        case 5:
          handleDepositedClose();
          break;
      }

      return;
    }
    if (endorsable && activeStep != 3) {
      setEndorsedFlag(endorsement?.backImage?.endorsed ? true : false);
    }
    if (submitError) {
      handleDepositedClose();
      return;
    }
    if (activeStep == 1 || activeStep === 3) {
      if (!fields.primaryBtn.onClick) {
        return;
      }
      if (endorsable && activeStep === 1) {
        setActiveStep(2);
        return;
      }
      setLoadingDeposit(true);

      if (
        selectedPayment.paymentMethodId === paymentMethods.ELECTRONIC_PAYMENT ||
        isPassportCheckCapture
      ) {
        withdrawItemToExternalAccount(
          selectedPayment.id,
          formData.accountId,
          formData.paymentMethodId
        )
          .catch((error) => {
            const errorMessage =
              error.response?.data?.message || "Unknown Error";

            setResponseData(errorMessage);
            setDepositError(true);
          })
          .then((resp) => {
            if (resp) {
              const responseData = resp.data;
              fireworksConfetti();
              setResponseData(responseData);
              setActiveStep(5);
              setAlreadyDeposited(true);
            }
          })
          .finally(() => {
            setLoadingDeposit(false);
          });
      } else {
        formData.note = formData.note || "";
        depositItem(
          { accountId: formData.accountId, note: formData.note },
          selectedPayment.id
        )
          .catch((error) => {
            const errorMessage =
              error.response?.data?.message || "Unknown Error";
            setResponseData(errorMessage);
            setDepositError(true);
            setLoadingDeposit(false);
          })
          .then((resp) => {
            if (resp) {
              const responseData = resp.data;
              setAlreadyDeposited(true);
              setResponseData(responseData);
              if (isACHTransfer || endorsable) {
                submitItem(formData);
                return;
              }
              setActiveStep(5);
            }
          });
      }

      return;
    }

    if (activeStep == 4 || activeStep == 5) {
      handleDepositedClose();
    }
    setActiveStep(activeStep + 1);
  };

  function submitItem(formData: any) {
    let result: any;

    if (endorsable) {
      const request: ISubmitCheckRequest = {
        items: [selectedPayment.id],
        chequeImageEndorsementListRequest: [
          {
            imageId: endorsement?.backImage?.id,
            accountId: selectedAccountId,
            rotate: checkIsRotated,
            coordinatesRequest: endorsed
              ? fields.endorsementRequest
              : undefined,
          },
        ],
        note: formData.note,
      };
      submitItems(request).then((resp: any) => {
        result = resp.data[0];
        handleSubmitItemResult(result);
      });
    }
    if (isACHTransfer) {
      submitACHItems([selectedPayment.id]).then((resp: any) => {
        result = resp.data[0];
        handleSubmitItemResult(result);
      });
    }
  }

  function handleSubmitItemResult(result: any) {
    setLoadingDeposit(false);
    const type = result.success ? "success" : "error";
    const message = result.message || result.error || result.reason || "";
    if (type === "error") {
      setSubmitError(true);
      setErrorMessage({
        title: `${strings.ITEM_ID}: ${result.id}`,
        subTitle: message,
      });
      if (message === SUBMIT_REJECTED_ERROR) {
        setSubmitRejected(true);
      }
      return;
    }
    fireworksConfetti();
    setActiveStep(5);
    setAlreadyDeposited(true);
  }

  function validateItem(formData: any) {
    setLoadingDeposit(true);

    submitPassportChecks(
      [selectedPayment!.id],
      formData.accountId,
      formData.paymentMethodId
    )
      .then((resp: any) => {
        if (resp.data[0].success) {
          setLoadingDeposit(false);
          setlreadyValidating(true);
          setActiveStep(5);
        }
      })
      .catch((error) => {
        const errorMessage = error.response?.data?.message || "Unknown Error";

        setResponseData(errorMessage);
        setValidateError(true);
        setLoadingDeposit(false);
      });
  }

  const backBtnLabel = () => {
    if (activeStep === 4 || activeStep === 5 || submitError) {
      return "";
    }
    return "Back";
  };
  const nextBtnLabel = () => {
    if (activeStep === 2 && isReceivableAccount) {
      if (validateError) {
        return "Try Again";
      }
      return "Validate Funds";
    }
    if (activeStep === 0 || (endorsable && activeStep === 1)) {
      return "Next";
    }
    if (depositError) {
      return "Try Again";
    }
    if (submitError) {
      return "Exit";
    }
    if (endorsable && activeStep === 2 && endorsedFlag) {
      return strings.DEPOSIT_WITH_ENDORSEMENT;
    }
    if (endorsable && activeStep === 2 && !endorsedFlag) {
      return strings.DEPOSIT_WITHOUT_ENDORSEMENT;
    }
    return "Confirm Deposit";
  };

  const nextBtnDisable = () => {
    if (endorsementRequired && !endorsedFlag && activeStep == 2) {
      return true;
    }
    return false;
  };

  const ConfirmDetails = () => {
    if (depositError) {
      return (
        <Container>
          <Container className={styles.responseContainer}>
            <Icon name={"icon_dialog_warning"} height={150} width={552} />
            <TypoGraph
              variant="body1"
              align="center"
              content={"Oh no! Something went wrong."}
            />
            <TypoGraph variant="body2" align="center" content={responseData} />
          </Container>
        </Container>
      );
    }
    if (submitError) {
      return (
        <Container>
          <Container className={styles.responseContainer}>
            <Icon name={"icon_dialog_warning"} height={150} width={552} />
            <TypoGraph
              variant="body1"
              align="center"
              content={errorMessage.title}
            />
            <TypoGraph
              variant="body2"
              align="center"
              content={errorMessage.subTitle}
            />
          </Container>
        </Container>
      );
    }

    if (validateError) {
      return (
        <Container>
          <Container className={styles.responseContainer}>
            <Icon name={"icon_dialog_warning"} height={150} width={552} />
            <TypoGraph
              variant="body1"
              align="center"
              content={"Error starting fund validation"}
            />
            <TypoGraph variant="body2" align="center" content={responseData} />
          </Container>
        </Container>
      );
    }

    return (
      <DataGrid
        columns={paymentDepositSumaryColumns}
        data={{
          account: selectedAccountLabel,
          amount: selectedPayment?.amount,
          paymentMethod: selectedMethodLabel || selectedPayment?.paymentMethod,
          notes: getValues("note"),
        }}
        hideIfEmpty={true}
      />
    );
  };

  const SuccessStep = () => {
    if (isReceivableAccount) {
      return (
        <Container>
          <Container className={styles.responseContainer}>
            <Icon name={"icon_dialog_success"} height={150} width={552} />
            <Container>
              <TypoGraph align="center" variant="body1">
                Validation of funds for receivable {selectedPayment?.id}{" "}
                initiated.
              </TypoGraph>
              <TypoGraph
                variant="body2"
                align="center"
                content={
                  "Once validated, it will be automatically deposited in the selected account."
                }
              />
            </Container>
          </Container>
        </Container>
      );
    }
    return (
      <Container>
        <Container className={styles.responseContainer}>
          <Icon name={"icon_dialog_success"} height={150} width={552} />
          <Container>
            <TypoGraph align="center" variant="body1">
              Receivable {responseData.itemId || responseData.id} has been{" "}
              {endorsable && <span>endorsed and </span>}
              deposited to <br />
              <span className={styles.boldText}> {selectedAccountLabel}</span>,
              in the amount of
              <span className={styles.boldText}>
                {" "}
                ${selectedPayment?.amount}.
              </span>
            </TypoGraph>
          </Container>
        </Container>
      </Container>
    );
  };

  const renderSteps = () => {
    switch (activeStep) {
      case 0:
        return (
          <Container>
            {!availableAccounts ||
              (availableAccounts?.length === 0 && (
                <TypoGraph
                  content={`${selectedPayment?.companyName} ${strings.HAS_NO_ACTIVE_ACCOUNTS}`}
                  color={colors.error}
                />
              ))}

            {availableAccounts && (
              <DropdownFormField
                disabled={!availableAccounts || availableAccounts.length === 0}
                defaultValue={undefined}
                name={"accountId"}
                label={strings.DESTINATION_ACCOUNT}
                required={true}
                options={availableAccounts}
              />
            )}
            {availablePaymentMethods && (
              <DropdownFormField {...availablePaymentMethods} />
            )}

            {isDeluxeAccount && (
              <InputFormField
                name={strings.NOTE.toLowerCase()}
                label={strings.OPTIONAL_NOTE}
                defaultValue={undefined}
                required={false}
              />
            )}
          </Container>
        );
      case 1:
        return endorsable ? (
          <Container>
            {endorsement && selectedAccountId && (
              <Container>
                {endorsement?.backImage && (
                  <CropEditor
                    payment={endorsement.payment}
                    backImage={endorsement.backImage}
                    frontImage={endorsement.frontImage}
                    imagesCropped={imagesCropped}
                    imagesGetsCropped={(value) => {
                      setImagesCropped(value);
                    }}
                  />
                )}
              </Container>
            )}
          </Container>
        ) : (
          <ConfirmDetails />
        );
      case 2:
        if (isReceivableAccount) {
          return <ConfirmDetails />;
        }
        return endorsable ? (
          <Container>
            {endorsement && selectedAccountId && (
              <Container>
                {endorsement.backImage && (
                  <Endorsement
                    {...endorsement}
                    refresh={endorseRefresh}
                    accountId={selectedAccountId}
                    setEndorsement={(val: boolean) => {
                      setEndorsedFlag(val);
                    }}
                    isEndorseRequired={(value) => {
                      setEndorsementRequired(value);
                    }}
                    isRotated={(value) => {
                      setCheckIsRotated(value);
                    }}
                    imagesCropped={imagesCropped}
                  />
                )}

                <TypoGraph
                  content={
                    endorsedFlag
                      ? strings.CHECK_IS_ALREADY_ENDORSED
                      : endorsementRequired && !endorsedFlag
                      ? strings.PLEASE_ADD_REQUIRED_ENDORSEMENT
                      : strings.YOU_CAN_ADD_OPTIONAL_ENDORSEMENT
                  }
                />
              </Container>
            )}
          </Container>
        ) : (
          <ConfirmDetails />
        );
      case 3:
        return <ConfirmDetails />;

      case 5:
        return <SuccessStep />;

      default:
        return <></>;
    }
  };

  const onSubmit = (formData: any) => {
    if (!fields.primaryBtn.onClick) {
      return;
    }
    handleStepNext(formData);
    return;
  };

  const handleScroll = useCallback(() => {
    setEndorseRefresh(!endorseRefresh);
  }, [endorseRefresh]);

  function handleDepositedClose() {
    if (!submitError && !isReceivableAccount) {
      toast({
        type: "success",
        title: strings.ITEM_DEPOSITED_SUCCESSFULLY,
        subTitle: `${strings.ITEM_ID}: ${
          responseData.itemId || responseData.id
        }`,
        autoClose: 1500,
      });
    }

    if (isReceivableAccount && !validateError) {
      toast({
        type: "success",
        title: strings.FUNDS_VALIDATION_INITIATED,
        subTitle: `${strings.ITEM_ID}: ${selectedPayment?.id}`,
        autoClose: 1500,
      });
    }
    handleClose();
    navigate(`/payments/${fields.routeTypeId}`);
  }
  return (
    <FormProvider {...methods}>
      <Dialog
        open={open}
        onClose={() => {
          handleClose();
          reset();
          if (alreadyDeposited || alreadyValidating) {
            handleDepositedClose();
          }
        }}
        maxWidth={endorsement ? "lg" : "sm"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent onScroll={() => handleScroll()}>
          <Container>
            <StepperComponent
              btnLabels={{
                backBtn: backBtnLabel(),
                nextBtn: nextBtnLabel(),
                submitBtn: "Done",
              }}
              disableNextBtn={nextBtnDisable()}
              activeStep={activeStep}
              loading={loadingDeposit}
              steps={steps}
              stepperClassName={styles.updateItemStepper}
              btnsAling={"end !important"}
              alternativeLabel
              handleNext={handleSubmit(onSubmit)}
              handleBack={handleStepBack}
            >
              <Container>{renderSteps()}</Container>
            </StepperComponent>
          </Container>
        </DialogContent>
      </Dialog>
    </FormProvider>
  );
};

export default DepositItemDialog;
