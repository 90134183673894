import { Container, Icon } from "components/index";
import { InputFormField } from "components/hookForm";
import strings from "strings";
import styles from "./userInfoForm.module.scss";
import { Box, Tooltip } from "@mui/material";
import regex from "regex";
import { allEarnestMoneyRoles } from "constants/dropdownOptions";
import {
  yourInfoEmailLabelByRole,
  yourInfoFirstNameLabelByRole,
  yourInfoLastNameLabelByRole,
} from "tools/labelsByRole";

interface UserInfoFormComponentProps {
  selectedRole?: string;
}

const UserInfoForm: React.FC<UserInfoFormComponentProps> = (props) => {
  const { selectedRole } = props;

  return (
    <Container className={styles.userInfoContainer}>
      <Container className={styles.enterNameContainer}>
        <InputFormField
          name="agentFirstName"
          label={yourInfoFirstNameLabelByRole(selectedRole!)}
          required
        />
        <InputFormField
          name="agentLastName"
          label={yourInfoLastNameLabelByRole(selectedRole!)}
          required
        />
        <div style={{ display: "flex", alignItems: "center" }}>
          <InputFormField
            name="agentEmail"
            label={yourInfoEmailLabelByRole(selectedRole!)}
            pattern={{
              value: regex.EMAIL_REGEX_PATTERN,
              message: strings.PLEASE_ENTER_A_VALID_EMAIL,
            }}
            required
          />
          <Tooltip title={strings.ENTER_YOUR_EMAIL_INFO} arrow>
            <Box ml={2}>
              <Icon name="icon_help" size={30}></Icon>
            </Box>
          </Tooltip>
        </div>
      </Container>
    </Container>
  );
};

export default UserInfoForm;
