import { Container, CustomDivider, UserInfoForm } from "components";
import { InputFormField, DropdownFormField } from "components/hookForm";
import styles from "../../transactionDetails.module.scss";
import regex from "regex";
import strings from "strings";
import { DropdownOptions } from "interfaces";
import { availableEarnestMoneyPlusOptionRoles } from "constants/dropdownOptions";
import { PropertyForm } from "components";
import { ICustomLabels } from "interfaces/IWhitelabelConfigurationResponse";
import React, { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import {
  dividerLabelByRole,
  emailLabelByRole,
  firstNameLabelByRole,
  lastNameLabelByRole,
  phoneNumberLabelByRole,
  showBasicAditionalInfoInputs,
} from "tools/labelsByRole";

interface EarnestMoneyPlusOptionComponentProps {
  statesOptions?: DropdownOptions[];
  customLabels?: ICustomLabels;
}

const EarnestMoneyPlusOptionComponent: React.FC<
  EarnestMoneyPlusOptionComponentProps
> = (props) => {
  const { getValues, setValue, control } = useFormContext();
  const { statesOptions, customLabels } = props;

  const selectedRoleWatch = useWatch({
    control,
    name: "trrole",
    defaultValue: getValues("trrole") || "",
  });

  useEffect(() => {
    if (getValues("trrole") === "") {
      setValue("buyerFirstName", undefined);
      setValue("buyerLastName", undefined);
      setValue("buyerEmail", undefined);
      setValue("buyerPhone", undefined);
    }
  }, [selectedRoleWatch]);

  return (
    <Container className={styles.transactionDetailContainer}>
      <DropdownFormField
        name={"trrole"}
        label={"Your role in the transaction"}
        options={availableEarnestMoneyPlusOptionRoles}
        required
      />
      {getValues("trrole") !== undefined && (
        <Container>
          <CustomDivider title={dividerLabelByRole(getValues("trrole"))} />
          <UserInfoForm selectedRole={getValues("trrole")} />
          <CustomDivider title="Additional Contact Information" />
          <Container className={styles.buyersContainer}>
            {showBasicAditionalInfoInputs(getValues("trrole")) && (
              <>
                <InputFormField
                  name="buyerFirstName"
                  label={firstNameLabelByRole(getValues("trrole"))}
                  required
                />
                <InputFormField
                  name="buyerLastName"
                  label={lastNameLabelByRole(getValues("trrole"))}
                  required
                />
                <InputFormField
                  name="buyerEmail"
                  label={emailLabelByRole(getValues("trrole"))}
                  pattern={{
                    value: regex.EMAIL_REGEX_PATTERN,
                    message: strings.PLEASE_ENTER_A_VALID_EMAIL,
                  }}
                  required
                />
              </>
            )}
            <InputFormField
              name="buyerPhone"
              label={phoneNumberLabelByRole(getValues("trrole"))}
              type="number"
              required
            />
            {/* <InputFormField name="name3" label="Seller's name" /> */}
            <InputFormField
              name="titleCoContact"
              label={"Title Co. Contact"}
              maxLength={35}
            />
          </Container>
        </Container>
      )}
      <CustomDivider title="Property Details" />
      <PropertyForm
        statesOptions={statesOptions}
        customLabels={customLabels?.address}
      />
    </Container>
  );
};

export default EarnestMoneyPlusOptionComponent;
