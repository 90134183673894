import React, { useCallback, useEffect, useState } from "react";
import {
  Container,
  DataGrid,
  Divider,
  Icon,
  Loader,
  TypoGraph,
} from "components";
import {
  paymentDetailsColumn,
  paymentRequestDetailsColumn,
} from "constants/tableData";
import { dcFailStatusById, maskPaymentStatus } from "constants/status";
import { IPayment, IPaymentImage, IPaymentImageResponse } from "interfaces";
import { DcFailedComponent } from "./partial/dcFailed";
import { DcNotFailedComponent } from "./partial/dcNotFailedComponent";
import { paymentMethods } from "constants/paymentMethods";
import styles from "../../paymentsList.module.scss";
import { getItemAttachment } from "api";
import { prFeeOptions } from "constants/prFeeOptions";
import { maskPropertyAddress } from "tools/format";
import { passportIds, paymentStatus } from "constants/paymentStatus";

interface IPaymentDetailProps {
  payment?: IPayment;
  paymentImage?: IPaymentImageResponse;
  handleImageNode: (index: "front" | "back" | "wiringConfirmation") => void;
}

const PaymentDetailComponent: React.FC<IPaymentDetailProps> = (props) => {
  const { payment, paymentImage, handleImageNode } = props;
  const [frontImage, setFrontImage] = useState<IPaymentImage | undefined>();
  const [backImage, setBackImage] = useState<IPaymentImage | undefined>();
  const [loading, setLoading] = useState(true);
  const isDcNotFailed =
    (payment?.statusId || payment?.statusId === 0) &&
    !dcFailStatusById.includes(payment?.statusId);
  const isPaymentRejected =
    payment?.statusId === paymentStatus.REJECTED ||
    payment?.statusId === paymentStatus.BP_REJECTED;
  const isPaymentRequested = payment?.statusId === paymentStatus.REQUESTED;
  const isPaymentRecurring = payment?.statusId === paymentStatus.RECURRING;
  const paymentTypeId = (payment?.typeId ||
    0) as keyof typeof paymentDetailsColumn.details;
  const paymentRequireAddress = payment?.typeId !== 5 && payment?.typeId !== 11;
  const showPayerAccountInfo =
    payment?.paymentMethodId === paymentMethods.ELECTRONIC_PAYMENT;
  const showReceivableAccount =
    payment?.statusId && passportIds.includes(payment.statusId);

  const loadImages = useCallback(async () => {
    if (!isDcNotFailed || !paymentImage) {
      setLoading(false);
      return;
    }
    try {
      setLoading(true);
      setFrontImage(paymentImage.images.find((i) => i.imageIndex === "FRONT"));
      setBackImage(paymentImage.images.find((i) => i.imageIndex === "BACK"));
    } finally {
      setLoading(false);
    }
  }, [payment, paymentImage, isDcNotFailed]);

  useEffect(() => {
    if (payment) {
      loadImages();
    }
  }, [loadImages, payment]); // executes once

  function downloadAttach(itemId: number) {
    getItemAttachment(itemId).then((response) => {
      const attachmentBase64 =
        "data:application/pdf;base64," + response.data.itemAttachmentBase64;
      let alink = document.createElement("a");
      alink.href = attachmentBase64;
      alink.download = payment?.attachment?.fileName!;
      alink.click();
    });
  }

  const dueDateData = () => {
    const type = payment?.prFeeType;
    const value = payment?.prFeeValue;
    const dueDate = payment?.prDueDate;
    const valueInDollars = (payment?.prFeeValue! / 100).toFixed(2);
    const valueText =
      type == prFeeOptions.PERCENTAGE ? `${value}%` : `$${valueInDollars}`;

    return { prDueDate: dueDate, prFeeValue: valueText };
  };

  const NoteAndAttachment = () => {
    return (
      <Container>
        <Divider title="NOTE AND ATTACHMENT" />
        {payment?.attachment && (
          <Container className={styles.filePreview}>
            <Container className={styles.fileNameContainer}>
              <Icon name="icon_uploadSuccess" />
              <TypoGraph
                variant="caption"
                content={payment.attachment.fileName}
                className={styles.text}
              />
            </Container>
            <Container className={styles.downloadIcon}>
              <Icon
                name="icon_filedownload"
                iconButton
                onClick={() => downloadAttach(payment.id)}
              />
            </Container>
          </Container>
        )}

        <DataGrid
          columns={[
            {
              name: "prNote",
              label: "Note",
            },
          ]}
          data={payment || {}}
          hideIfEmpty={true}
        />
      </Container>
    );
  };

  const requestedPaymentDetails = (
    <>
      <Divider title="REQUEST DETAILS" />
      <DataGrid
        columns={paymentRequestDetailsColumn.requestDetails[0]}
        data={payment || {}}
        hideIfEmpty={false}
      />
      <Divider title="COMPANY" />
      <DataGrid
        columns={paymentRequestDetailsColumn.companyDetails[0]}
        data={payment || {}}
        hideIfEmpty={false}
      />
      <Divider title="PAYER DETAILS" />
      <DataGrid
        columns={paymentRequestDetailsColumn.payerDetails[0]}
        data={payment || {}}
        hideIfEmpty={false}
      />
      {paymentRequireAddress && (
        <>
          <Divider title="PROPERTY" />
          <DataGrid
            columns={paymentRequestDetailsColumn.propertyDetails[0]}
            data={maskPropertyAddress(payment) || {}}
            hideIfEmpty={false}
          />
        </>
      )}

      <Divider title="TRANSACTION DETAILS" />
      <DataGrid
        columns={paymentRequestDetailsColumn.transactionDetails[0]}
        data={payment || {}}
        hideIfEmpty={false}
      />
    </>
  );

  const recurringPaymentDetails = (
    <>
      <Divider title="RECURRENCY DETAILS" />
      <DataGrid
        columns={paymentRequestDetailsColumn.recurringDetails[0]}
        data={payment || {}}
        hideIfEmpty={false}
      />
      <Divider title="COMPANY" />
      <DataGrid
        columns={paymentRequestDetailsColumn.companyDetails[0]}
        data={payment || {}}
        hideIfEmpty={false}
      />
      <Divider title="PAYER DETAILS" />
      <DataGrid
        columns={paymentRequestDetailsColumn.payerDetails[0]}
        data={payment || {}}
        hideIfEmpty={false}
      />
      {paymentRequireAddress && (
        <>
          <Divider title="PROPERTY" />
          <DataGrid
            columns={paymentRequestDetailsColumn.propertyDetails[0]}
            data={maskPropertyAddress(payment) || {}}
            hideIfEmpty={false}
          />
        </>
      )}

      <Divider title="TRANSACTION DETAILS" />
      <DataGrid
        columns={paymentRequestDetailsColumn.transactionDetails[0]}
        data={payment || {}}
        hideIfEmpty={false}
      />
    </>
  );

  return (
    <Container sx={{ paddingTop: "10px" }}>
      <Loader loading={loading} />
      {isPaymentRequested ? (
        requestedPaymentDetails
      ) : isPaymentRecurring ? (
        recurringPaymentDetails
      ) : (
        <>
          <DataGrid
            columns={
              paymentDetailsColumn.details[paymentTypeId] ||
              paymentDetailsColumn.details.default
            }
            data={maskPaymentStatus(payment) || {}}
            hideIfEmpty={true}
          />

          {isPaymentRejected && (
            <DataGrid
              columns={[
                {
                  name: "rejectionType",
                  label: "Rejection Type",
                },
              ]}
              data={payment || {}}
            />
          )}

          {isDcNotFailed ? (
            <DcNotFailedComponent
              frontImage={frontImage}
              backImage={backImage}
              wireConfirmationImageBase64={undefined}
              handleImageNode={handleImageNode}
            />
          ) : (
            <DcFailedComponent payment={payment} />
          )}
          {payment?.wireConfirmationImageBase64 && (
            <DcNotFailedComponent
              frontImage={undefined}
              backImage={undefined}
              wireConfirmationImageBase64={payment.wireConfirmationImageBase64}
              handleImageNode={handleImageNode}
            />
          )}
          {paymentRequireAddress && (
            <>
              <Divider title="PROPERTY" />
              <DataGrid
                columns={paymentDetailsColumn.address}
                data={payment?.address || {}}
                hideIfEmpty={false}
              />
            </>
          )}
          {showPayerAccountInfo && (
            <Container>
              <Divider title="PAYER ACCOUNT INFORMATION" />
              <DataGrid
                columns={paymentDetailsColumn.payerAccountInformation}
                data={payment || {}}
              />
            </Container>
          )}
          {showReceivableAccount ? (
            <Container>
              <Divider title="RECEIVABLE ACCOUNT" />
              <DataGrid
                columns={paymentDetailsColumn.receivableAccount}
                data={payment || {}}
              />
            </Container>
          ) : (
            <Container>
              <Divider title="RECEIVING COMPANY" />
              <DataGrid
                columns={paymentDetailsColumn.receivingCompany}
                data={payment || {}}
                hideIfEmpty={true}
              />
            </Container>
          )}
        </>
      )}
      {payment?.prDueDate && (
        <Container>
          <Divider title="PAYMENT REQUEST DETAILS" />
          <DataGrid
            columns={paymentDetailsColumn.paymentRequestDetail}
            data={dueDateData() || {}}
          />
        </Container>
      )}
      {(payment?.attachment || payment?.prNote) && (
        <Container>
          <NoteAndAttachment />
        </Container>
      )}
    </Container>
  );
};

export default PaymentDetailComponent;
