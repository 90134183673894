import { Box, Container } from "@mui/material";
import { Icon, TypoGraph } from "components";
import { Component, ReactNode } from "react";
import strings from "strings";
import colors from "theme/colors";
interface IProps {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<IProps, State> {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={{ backgroundColor: "#F7F5FF", justifyItems: "center" }}>
          <Box component="main">
            <div style={{ padding: "8rem" }}></div>
            <div
              style={{
                backgroundColor: "#FFFFFF",
                borderRadius: "16px",
                padding: "1rem",
              }}
            >
              <Container sx={{ justifyItems: "center" }}>
                <Icon name="icon_logoFullname" height={80} width={260} />
                <Container
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center !important",
                    marginTop: "-5px",
                    gap: "6px",
                  }}
                >
                  <TypoGraph
                    sx={{ fontStyle: "italic" }}
                    content={"the good funds payment portal"}
                  />
                </Container>
                <TypoGraph sx={{ marginTop: "20px" }} content={"🚨"} />
                <TypoGraph
                  variant="h2"
                  content={strings.ERROR_BOUNDRY_HEADER}
                />
                <TypoGraph
                  sx={{ marginTop: "10px" }}
                  variant="body2"
                  content={strings.ERROR_BOUNDRY_BODY}
                />
              </Container>
            </div>
            <Container
              sx={{
                marginTop: "40px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
              }}
            >
              <Icon name="icon_b_logo_primary_bg" height={35} width={45} />

              <TypoGraph
                variant="overline"
                content="© 2025, Bank Shot"
                align="center"
                color={colors.grey}
                sx={{
                  fontSize: "10px !important",
                }}
              />
            </Container>
          </Box>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
