import React, { useCallback, useEffect, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { AxiosResponse } from "axios";
import {
  createNewBillingAccount,
  getAdminsForCompanies,
  getBillingAccountDetails,
  getBillingAccountStripeSubscription,
  getCompaniesWithBillingAccounts,
  getCompaniesWithNoBillingAccounts,
  getPriceProducts,
  updateBillingAccount,
} from "api";
import {
  DropdownOptions,
  IBillingAccountResponse,
  ICompanyIdList,
  INewBillingAccountRequest,
  IStripeSubscription,
  IUpdateBillingAccountRequest,
} from "interfaces";
import {
  Button,
  Card,
  Container,
  Divider,
  HeaderBar,
  Loader,
  Toast as toast,
  TypoGraph,
} from "components";
import {
  DropdownFormField,
  InputFormField,
  MultiSelectFormField,
} from "components/hookForm";
import styles from "./billingAccountForm.module.scss";
import strings from "strings";

const BillingAccountForm: React.FunctionComponent = () => {
  const methods = useForm<
    INewBillingAccountRequest | IUpdateBillingAccountRequest
  >({
    reValidateMode: "onChange",
    defaultValues: {
      priceProducts: [{}],
    },
  });

  const [billingAccountPreviousData, setBillingAccountPreviousData] =
    useState<IBillingAccountResponse>();

  const { handleSubmit, reset, getValues, watch, control, setValue } = methods;
  const { billingAccountId } = useParams();
  const isEdit = !!billingAccountId;
  const navigate = useNavigate();

  const [companiesOptions, setCompaniesOptions] = useState<DropdownOptions[]>();
  const [pricesOptions, setPricesOptions] = useState<DropdownOptions[]>();
  const [adminUsersOptions, setAdminUsersOptions] =
    useState<DropdownOptions[]>();
  const [productsOptions, setProductsOptions] = useState<DropdownOptions[]>();
  const [
    companiesWithNoBillingAccountOptions,
    setCompaniesWithNoBillingAccountOptions,
  ] = useState<DropdownOptions[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [btnLoader, setBtnLoader] = useState<boolean>(false);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "priceProducts",
  });

  const watchFieldArray = watch("priceProducts");

  const loadAdminUsersForCompanies = (ids: number[]) => {
    if (!ids || ids.length === 0) {
      return;
    }
    const request: ICompanyIdList = { adminCompanies: ids };
    getAdminsForCompanies(request).then((response) => {
      const adminUserOptions = response?.data?.map((user) => {
        return {
          value: user.id,
          label: user.email,
        };
      });
      const cardholderUser = adminUserOptions.find(
        (user) => user.value == getValues("cardHolderUserId")
      );

      if (!cardholderUser) {
        setValue("cardHolderUserId", undefined);
      }
      setAdminUsersOptions(adminUserOptions);
    });
  };

  useEffect(() => {
    if (!!billingAccountPreviousData) {
      /* Setting up the form payload to populate the form in the edit screen */
      const companiesIdsFromData = billingAccountPreviousData.companies.map(
        (company: any): number => company.companyId
      );
      const priceProductsFromData =
        billingAccountPreviousData.priceProducts.map((priceProduct) => {
          setPricesOptions((prev) => {
            const newState = prev?.map((item) => {
              if (item.value === priceProduct.stripePriceId) {
                return { ...item, hide: true };
              }
              return item;
            });
            return newState;
          });
          setProductsOptions((prev) => {
            const newState = prev?.map((item) => {
              if (item.value === priceProduct.billingProductId) {
                return { ...item, hide: true };
              }
              return item;
            });
            return newState;
          });
          return {
            billingProductId: priceProduct.billingProductId,
            stripePriceId: priceProduct.stripePriceId,
            id: priceProduct.id,
          };
        });
      loadAdminUsersForCompanies(companiesIdsFromData);
      const formValues = {
        name: billingAccountPreviousData.name,
        companiesIds: companiesIdsFromData,
        cardHolderUserId: billingAccountPreviousData.cardholder.id,
        priceProducts: priceProductsFromData,
      };

      reset(formValues);
      setValue("cardHolderUserId", billingAccountPreviousData.cardholder.id);
    }
  }, [billingAccountPreviousData, reset]);

  const navigateToBillingAccounts = () => {
    navigate("/settings/billingaccounts");
  };

  const handleOptionValidation = (formValues?: any) => {
    const priceProductValues = formValues || watchFieldArray;
    const priceId = priceProductValues?.map(
      (item: any) => item.priceId || item.stripePriceId
    );
    const priceOption = pricesOptions?.map((item) => {
      if (item.value && priceId.includes(item.value.toString())) {
        item["hide"] = true;
      } else {
        item["hide"] = false;
      }
      return item;
    });
    const productId = priceProductValues?.map(
      (item: any) => item.billingProductId
    );
    const productOption = productsOptions?.map((item) => {
      if (productId.includes(+item.value)) {
        item["hide"] = true;
      } else {
        item["hide"] = false;
      }
      return item;
    });
    setPricesOptions(priceOption);
    setProductsOptions(productOption);
  };

  const increasePriceProductArray = () => {
    append({});
    const formValues = getValues("priceProducts");
    handleOptionValidation(formValues);
  };

  const setDefaultLoaders = () => {
    setBtnLoader(false);
  };

  const loadPriceProductsList = () => {
    setLoading(true);
    getPriceProducts()
      .then((response) => {
        const pricesOptions = response?.data?.prices.map((price) => {
          return {
            value: price.priceId,
            label: price.productName,
          };
        });
        const productsOptions = response?.data?.products.map((product) => {
          return {
            value: product.id,
            label: product.name,
          };
        });
        setPricesOptions(pricesOptions);
        setProductsOptions(productsOptions);
      })
      .finally(() => setLoading(false));
  };

  const loadCompaniesWithNoBillingAccounts = () => {
    setLoading(true);
    getCompaniesWithNoBillingAccounts()
      .then((response) => {
        const companyOptions = response?.data?.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        });
        setCompaniesWithNoBillingAccountOptions(companyOptions);
      })
      .finally(() => setLoading(false));
  };

  const loadCompaniesWithBillingAccounts = () => {
    setLoading(true);
    getCompaniesWithBillingAccounts()
      .then((response) => {
        const companyOptions = response?.data?.map((company) => {
          return {
            value: company.id,
            label: company.name,
          };
        });
        setCompaniesOptions(companyOptions);
      })
      .finally(() => setLoading(false));
  };

  const billingAccountHasActiveSub = (
    billingAccountStripeSubs: IStripeSubscription[]
  ) => {
    if (!billingAccountStripeSubs || billingAccountStripeSubs.length === 0) {
      return false;
    }
    const activeSubs = billingAccountStripeSubs.filter(
      (sub) => sub.status === "active"
    );
    return activeSubs.length > 0;
  };

  const loadBillingAccountDetails = useCallback((billingAccountId: string) => {
    setLoading(true);
    getBillingAccountStripeSubscription(billingAccountId)
      .then((response) => {
        if (!billingAccountHasActiveSub(response?.data)) {
          toast({
            type: "error",
            title: strings.BILLING_ACCOUNT_CANT_BE_UPDATED,
            subTitle: strings.STRIPE_SUB_NOT_ACTIVE,
          });
          navigateToBillingAccounts();
        }
      })
      .catch(() => navigateToBillingAccounts());
    getBillingAccountDetails(billingAccountId)
      .then((response) => {
        setBillingAccountPreviousData(response.data);
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    const companiesIdsFromData = billingAccountPreviousData?.companies.map(
      (company: any): number => company.companyId
    );
    if (watch("companiesIds") != companiesIdsFromData) {
      loadAdminUsersForCompanies(getValues("companiesIds"));
    }
  }, [watch("companiesIds")]);

  useEffect(() => {
    loadAdminUsersForCompanies(getValues("companiesIds"));
    loadPriceProductsList();
    loadCompaniesWithNoBillingAccounts();
    loadCompaniesWithBillingAccounts();
    if (!!billingAccountId) {
      loadBillingAccountDetails(billingAccountId);
    }
  }, [billingAccountId]);

  const onSubmit = (
    billingAccountForm:
      | INewBillingAccountRequest
      | IUpdateBillingAccountRequest,
    type: string
  ) => {
    let action;
    setBtnLoader(true);

    switch (type) {
      case strings.CREATE:
        action = createNewBillingAccount(billingAccountForm).then(
          (resp: AxiosResponse<IBillingAccountResponse>) => {
            toast({
              title: "Created Successfully",
              subTitle: `id: ${resp.data.id}`,
            });
            navigateToBillingAccounts();
          }
        );
        break;
      case strings.EDIT:
        if (billingAccountId) {
          action = updateBillingAccount(
            billingAccountForm,
            billingAccountId
          ).then(() => {
            toast({
              title: "Updated Successfully",
              subTitle: `id: ${billingAccountId}, name: ${billingAccountForm.name}`,
            });
            navigateToBillingAccounts();
          });
        }
        break;
      default:
        break;
    }

    action?.finally(() => {
      setDefaultLoaders();
    });
  };

  const handleRemoveIndex = (index: number) => {
    remove(index);
    const formValues = getValues("priceProducts");
    handleOptionValidation(formValues);
  };

  return (
    <Container>
      <HeaderBar
        title={`${isEdit ? strings.EDIT : strings.CREATE} ${
          strings.BILLING_ACCOUNT_STRING.BILLING_ACCOUNT
        }`}
        primaryBtnProps={{
          id: isEdit
            ? strings.BILLING_ACCOUNT_STRING.UPDATE_BILLING_ACCOUNT
            : strings.BILLING_ACCOUNT_STRING.CREATE_BILLING_ACCOUNT,
          label: isEdit
            ? strings.BILLING_ACCOUNT_STRING.UPDATE_BILLING_ACCOUNT
            : strings.BILLING_ACCOUNT_STRING.CREATE_BILLING_ACCOUNT,
          onClick: handleSubmit((data) =>
            onSubmit(data, isEdit ? strings.EDIT : strings.CREATE)
          ),
          loading: btnLoader,
        }}
      />
      <Loader loading={loading} />
      <Card>
        <TypoGraph
          variant="h2"
          sx={{ paddingTop: 1, paddingBottom: 2 }}
          content={`${
            isEdit
              ? `${strings.EDIT} ${strings.BILLING_ACCOUNT_STRING.BILLING_ACCOUNT}`
              : `${strings.CREATE} ${strings.BILLING_ACCOUNT_STRING.BILLING_ACCOUNT}`
          }`}
        />
        <Container>
          <FormProvider {...methods}>
            <InputFormField
              name="name"
              label={"Name"}
              required
              minLength={{ value: 2, message: "Minimum length is  2" }}
              maxLength={100}
            />
            <Container className={styles.billingAccountFormContainer}>
              <MultiSelectFormField
                name="companiesIds"
                label={"Non associated companies"}
                options={companiesWithNoBillingAccountOptions}
              />
              <MultiSelectFormField
                name="companiesIds"
                label={"All companies"}
                options={companiesOptions}
                required
              />
              <DropdownFormField
                name="cardHolderUserId"
                type={"number"}
                label={"Cardholder User"}
                options={adminUsersOptions}
                required
              />
              {!isEdit && (
                <InputFormField
                  name="customerId"
                  label={"Existing Customer ID"}
                  minLength={{ value: 2, message: "Minimum length is  2" }}
                  maxLength={100}
                />
              )}
            </Container>

            <Divider title="Pricing" />
            {fields.map((field, index) => {
              return (
                <Container className={styles.pricingContainer} key={field.id}>
                  <MultiSelectFormField
                    name={`priceProducts.${index}.billingProductId`}
                    label={"Products"}
                    options={productsOptions}
                    required
                    isMulti={false}
                  />
                  {!isEdit && (
                    <MultiSelectFormField
                      name={`priceProducts.${index}.priceId`}
                      label={"Prices"}
                      options={pricesOptions}
                      isMulti={false}
                      required
                    />
                  )}
                  {isEdit && (
                    <MultiSelectFormField
                      name={`priceProducts.${index}.stripePriceId`}
                      label={"Prices"}
                      options={pricesOptions}
                      isMulti={false}
                      required
                    />
                  )}
                  {(index > 0 || getValues("priceProducts").length > 1) && (
                    <Button
                      id={"remove" + index}
                      className={styles.removeItemButton}
                      variant="outlined"
                      color="error"
                      label={"Remove"}
                      onClick={() => {
                        handleRemoveIndex(index);
                      }}
                    />
                  )}
                </Container>
              );
            })}
            <Container sx={{ justifyContent: "center", padding: "2rem" }}>
              <Button
                id={"addProduct"}
                className={styles.addProductButton}
                fullWidth={false}
                variant="outlined"
                label={"Add another product"}
                onClick={() => increasePriceProductArray()}
                disabled={productsOptions?.length === fields.length}
              />
            </Container>
          </FormProvider>
        </Container>
        <Divider />
        <Container className={styles.actionContainer}>
          {isEdit ? (
            <Button
              id={
                strings.BILLING_ACCOUNT_STRING.UPDATE_BILLING_ACCOUNT +
                " Bottom"
              }
              loading={btnLoader}
              label={strings.BILLING_ACCOUNT_STRING.UPDATE_BILLING_ACCOUNT}
              onClick={handleSubmit((data) => onSubmit(data, strings.EDIT))}
            />
          ) : (
            <Button
              id={
                strings.BILLING_ACCOUNT_STRING.CREATE_BILLING_ACCOUNT +
                " Bottom"
              }
              loading={btnLoader}
              label={strings.BILLING_ACCOUNT_STRING.CREATE_BILLING_ACCOUNT}
              onClick={handleSubmit((data) => {
                onSubmit(data, strings.CREATE);
              })}
            />
          )}
          <Button
            id={strings.CANCEL}
            variant="outlined"
            label={strings.CANCEL}
            onClick={() => navigateToBillingAccounts()}
          />
        </Container>
      </Card>
    </Container>
  );
};

export default BillingAccountForm;
