import { allEarnestMoneyRoles } from "constants/dropdownOptions";

function dividerLabelByRole(role: string) {
  switch (role) {
    case allEarnestMoneyRoles[0].value:
    case allEarnestMoneyRoles[3].value:
      return "Buyer's Info";
    case allEarnestMoneyRoles[1].value:
    case allEarnestMoneyRoles[2].value:
      return "Seller's Info";
  }
  return "Agent's Info";
}

function yourInfoFirstNameLabelByRole(role: string) {
  switch (role) {
    case allEarnestMoneyRoles[0].value:
    case allEarnestMoneyRoles[3].value:
      return "Buyer's First Name";
    case allEarnestMoneyRoles[1].value:
    case allEarnestMoneyRoles[2].value:
      return "Seller's First Name";
  }
  return "Agent's First Name";
}

function yourInfoLastNameLabelByRole(role: string) {
  switch (role) {
    case allEarnestMoneyRoles[0].value:
    case allEarnestMoneyRoles[3].value:
      return "Buyer's Last Name";
    case allEarnestMoneyRoles[1].value:
    case allEarnestMoneyRoles[2].value:
      return "Seller's Last Name";
  }
  return "Agent's Last Name";
}

function yourInfoEmailLabelByRole(role: string) {
  switch (role) {
    case allEarnestMoneyRoles[0].value:
    case allEarnestMoneyRoles[3].value:
      return "Buyer's Email";
    case allEarnestMoneyRoles[1].value:
    case allEarnestMoneyRoles[2].value:
      return "Seller's Email";
  }
  return "Agent's Email";
}

function firstNameLabelByRole(role: string) {
  switch (role) {
    case allEarnestMoneyRoles[0].value:
      return "Agent's First Name";
  }
  return "Buyer's First Name";
}

function lastNameLabelByRole(role: string) {
  switch (role) {
    case allEarnestMoneyRoles[0].value:
      return "Agent's Last Name";
  }
  return "Buyer's Last Name";
}

function emailLabelByRole(role: string) {
  switch (role) {
    case allEarnestMoneyRoles[0].value:
      return "Agent's Email";
  }
  return "Buyer's Email";
}

function phoneNumberLabelByRole(role: string) {
  switch (role) {
    case allEarnestMoneyRoles[0].value:
      return "Agent's Phone Number";
    case allEarnestMoneyRoles[3].value:
    case allEarnestMoneyRoles[4].value:
      return "Buyer's Phone Number";
  }
  return "Seller's Phone Number";
}

function showBasicAditionalInfoInputs(role: string) {
  if (
    role == allEarnestMoneyRoles[0].value ||
    role == allEarnestMoneyRoles[4].value
  ) {
    return true;
  }

  return false;
}

export {
  dividerLabelByRole,
  yourInfoFirstNameLabelByRole,
  yourInfoLastNameLabelByRole,
  yourInfoEmailLabelByRole,
  firstNameLabelByRole,
  lastNameLabelByRole,
  emailLabelByRole,
  phoneNumberLabelByRole,
  showBasicAditionalInfoInputs,
};
