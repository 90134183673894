import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  getWhitelabelConfigurationById,
  getAllWhitelabelConfigurations,
} from "api";
import { IPageResponse } from "interfaces";
import {
  Blade,
  Button,
  Container,
  DataGrid,
  Divider,
  HeaderBar,
  Icon,
  Loader,
  SubmitDialog,
  TableComponent,
  Toast as toast,
  TypoGraph,
} from "components";
import { whiteLabelDetailsLabel, whiteLabelList } from "constants/tableData";
import strings from "strings";
import styles from "./whiteLabelsPage.module.scss";
import {
  GridSortDirection,
  GridSortModel,
} from "@mui/x-data-grid/models/gridSortModel";
import { IWhitelabelConfigResponse } from "interfaces/IWhitelabelConfigurationRequest";
import { Actions, Resources, userCan } from "tools/privilegeChecker";

const WhiteLabelsPage: React.FunctionComponent = () => {
  const { whiteLabelColumns } = whiteLabelList;
  const [WhitelabelConfigurationPage, setWhitelabelConfigurationPage] =
    useState<IPageResponse<IWhitelabelConfigResponse>>();
  const [whitelabelConfiguration, setWhitelabelConfiguration] =
    useState<IWhitelabelConfigResponse>();
  const [selectedWhitelabelConfiguration, setSelectedWhitelabelConfiguration] =
    useState<number | string>(0);

  const [loading, setLoading] = useState(false);
  const [bladeLoading, setBladeLoading] = useState(false);

  const [usageMonth, setUsageMonth] = useState<Date>(new Date());
  const [searchValue, setSearchValue] = useState<string>("");
  const [whitelabelConfig, setWhitelabelConfig] =
    useState<IWhitelabelConfigResponse>();
  const [showBlade, setShowBlade] = useState(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [deleteBtnLoader, setDeleteBtnLoader] = useState<boolean>(false);
  const navigate = useNavigate();
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "id",
      sort: "desc",
    },
  ]);
  const [showUpdateCustomerModal, setShowUpdateCustomerModal] =
    useState<boolean>(false);
  const [currentPageSize, setCurrentPageSize] = useState<number>(10);
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(0);

  const handleSortChange = (model: GridSortModel) => {
    if (JSON.stringify(model) !== JSON.stringify(sortModel)) {
      setSortModel(model);
    }
  };
  const handleQuickSearch = (value: string) => {
    setSearchValue(value);
    loadWhitelabelConfigurations(
      currentPageNumber,
      currentPageSize,
      value,
      sortModel[0]?.field,
      sortModel[0]?.sort
    );
  };
  whiteLabelColumns[0].handleClick = (id) => {
    setBladeLoading(true);
    navigate(`/settings/whitelabels/${id}`);
    setSelectedWhitelabelConfiguration(id);
    setShowBlade(true);
    setBladeLoading(false);
  };

  useEffect(() => {
    if (selectedWhitelabelConfiguration !== 0) {
      getWhitelabelConfigurationById(selectedWhitelabelConfiguration).then(
        (r) => {
          setWhitelabelConfig(r.data);
          setBladeLoading(false);
        }
      );
    }
  }, [selectedWhitelabelConfiguration]);

  useEffect(() => {
    setBladeLoading(true);

    if (selectedWhitelabelConfiguration !== 0) {
      setWhitelabelConfiguration(
        WhitelabelConfigurationPage?.items?.find(
          (whitelabelConfig) =>
            whitelabelConfig.id === selectedWhitelabelConfiguration
        )
      );
    }
  }, [selectedWhitelabelConfiguration, WhitelabelConfigurationPage]);

  const loadWhitelabelConfigurations = useCallback(
    (
      page: number = 0,
      pageSize: number = 10,
      searchValue: string = "",
      sortBy?: string,
      order?: GridSortDirection
    ) => {
      setLoading(true);
      getAllWhitelabelConfigurations(
        page,
        pageSize,
        searchValue,
        sortBy || "",
        order
      )
        .then((whitelabelConfigurations) => {
          setWhitelabelConfigurationPage(whitelabelConfigurations.data);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [sortModel]
  );

  useEffect(() => {
    loadWhitelabelConfigurations(
      currentPageNumber,
      currentPageSize,
      searchValue,
      sortModel[0]?.field,
      sortModel[0]?.sort
    );
  }, [loadWhitelabelConfigurations]);

  const handleCreateNewWhitelabelConfiguration = () => {
    navigate("/settings/whitelabels/create");
  };

  const handleActions = (id: number | string, actionName: string) => {
    setSelectedWhitelabelConfiguration(id);
    switch (actionName) {
      case strings.VIEW:
        setSelectedWhitelabelConfiguration(id);
        setShowBlade(true);
        break;
      case strings.EDIT:
        navigate(`/settings/whitelabels/edit/${id}`);
        break;
      default:
        break;
    }
  };

  const BladeContent = () => {
    const payload = { ...whitelabelConfiguration };

    const getCompanyName = (companies: any) => {
      return companies.map((item: any) => item.name);
    };

    payload.companies = getCompanyName(payload?.companies || []);

    console.log("payload", whitelabelConfiguration);

    if (bladeLoading) {
      return <Loader loading={bladeLoading} />;
    }

    return (
      <Container className={styles.dataGridContainer}>
        <DataGrid columns={whiteLabelDetailsLabel.details} data={payload} />
        <Divider title="Images" />
        <Container className={styles.dataGridContainerImg}>
          <TypoGraph content={"Fav icon"} />
          {whitelabelConfig?.favIconBase64 ? (
            <img
              height={46}
              src={whitelabelConfig.favIconBase64}
              alt="Uploaded Preview"
              className={styles.previewImage}
            />
          ) : (
            <Icon name="icon_camera" size={46} />
          )}
        </Container>
        <Container className={styles.dataGridContainerImg}>
          <TypoGraph content={"Logo"} />
          {whitelabelConfig?.logoBase64 ? (
            <img
              height={46}
              src={whitelabelConfig.logoBase64}
              alt="Uploaded Preview"
              className={styles.previewImage}
            />
          ) : (
            <Icon name="icon_camera" size={46} />
          )}
        </Container>
        <Divider title="Associated Companies" />
        <DataGrid columns={whiteLabelDetailsLabel.companies} data={payload} />
        <Divider title="Config details" />
        <DataGrid
          columns={whiteLabelDetailsLabel.configJson}
          data={payload.configJson}
        />
      </Container>
    );
  };

  const handleCopyToClipboard = (subDomain: any) => {
    const baseURL = window.location.origin;
    const textArea = document.createElement("textarea");
    if (!!subDomain) {
      textArea.value =
        "https://" + subDomain + ".getbankshot.com" + "/receivables";
    } else {
      textArea.value = baseURL + "/receivables";
    }
    document.body.appendChild(textArea);
    textArea.select();
    try {
      document.execCommand("copy");
      document.body.removeChild(textArea);
      toast({
        title: "Company URL copied successfully",
      });
    } catch (error) {
      toast({
        title: "Error on copy Company URL",
        type: "error",
      });
    }
  };

  const BladeFooter = () => {
    return (
      <Container className={styles.actionContainer}>
        <Button
          size={"small"}
          id={strings.COPY_URL}
          variant="outlined"
          label={strings.COPY_URL}
          onClick={() =>
            handleCopyToClipboard(whitelabelConfiguration?.subDomain)
          }
        />
        {userCan(Actions.UPDATE, Resources.WHITELABELS) && (
          <Button
            id={strings.UPDATE}
            variant="outlined"
            label={strings.UPDATE}
            disabled={false}
            onClick={() => {
              navigate(
                `/settings/whitelabels/${whitelabelConfiguration?.id}/edit`
              );
            }}
          />
        )}
      </Container>
    );
  };

  return (
    <>
      <HeaderBar
        title={strings.WHITE_LABELS_STRING.WHITE_LABELS_ACCOUNTS}
        primaryBtnProps={
          userCan(Actions.WRITE, Resources.WHITELABELS)
            ? {
                id: strings.WHITE_LABELS_STRING.CREATE_WHITE_LABEL,
                label: strings.WHITE_LABELS_STRING.CREATE_WHITE_LABEL,
                onClick: handleCreateNewWhitelabelConfiguration,
              }
            : undefined
        }
      />
      <TableComponent
        title={strings.WHITE_LABEL_TABLE_TITLE}
        data={WhitelabelConfigurationPage?.items || []}
        totalCount={WhitelabelConfigurationPage?.totalCount}
        columns={whiteLabelColumns}
        loading={loading}
        handleActions={handleActions}
        handleCheckboxSelection={() => {}}
        handleSelectionActions={() => {}}
        searchValue={searchValue}
        isSearchable={true}
        handleQuickSearch={handleQuickSearch}
        handlePagination={(currentPage, pageSize) => {
          setCurrentPageSize(pageSize);
          setCurrentPageNumber(currentPage);
          loadWhitelabelConfigurations(
            currentPage,
            pageSize,
            searchValue,
            sortModel[0]?.field,
            sortModel[0]?.sort
          );
        }}
        handleSortModelChange={handleSortChange}
      />
      <Blade
        show={showBlade}
        title={strings.WHITE_LABELS_STRING.WHITE_LABEL_DETAILS}
        handleClose={() => {
          setShowBlade(false);
          setSelectedWhitelabelConfiguration(0);
          navigate(`/settings/whitelabels`);
        }}
        content={<BladeContent />}
        footerContent={<BladeFooter />}
      />
    </>
  );
};

export default WhiteLabelsPage;
