import React, { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
  GridRowsProp,
  GridSelectionModel,
  GridSortModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { dataGridSx } from "constants/tableData";
import { Card, Container, Icon, TypoGraph } from "components";
import styles from "./table.module.scss";
import colors from "theme/colors";
import { TextField } from "components/controllers";

interface IActionList {
  id: string;
  icon: string;
  name: string;
  className?: string;
  iconSize?: number;
  selection?: boolean;
}

interface ITableProps {
  loading?: boolean;
  columns: GridColDef[];
  data: GridRowsProp;
  searchValue?: string;
  totalCount?: number;
  title?: string;
  defaultPageSize?: number;
  checkboxSelection?: boolean;
  actionList?: IActionList[];
  disableActions?: number[];
  density?: "compact" | "standard" | "comfortable";
  currentPageSize?: number;
  handleQuickSearch?: (value: string) => void;
  handleCheckboxSelection?: (selections: GridSelectionModel) => void;
  handleActions?: (id: number, name: string) => void;
  handleSelectionActions?: (
    name: string,
    selectionModel: GridSelectionModel
  ) => void;
  handlePagination?: (pageCount: number, pageSize: number) => void;
  isRowSelectable?: (params: GridRowParams<any>) => boolean;
  serverPaginationMode?: boolean;
  handleCustomFilterButton?: () => void;
  serverSortingMode?: boolean;
  isSearchable?: boolean;
  handleSortModelChange?: (model: GridSortModel) => void;
  onRowClick?: (model: GridRowParams) => void;
  columnVisibilityModel?: any;
}

interface ICustomToolbar {
  title: string;
  selectionModel?: GridSelectionModel;
  actionList?: IActionList[];
  quickSearchValue?: string;
  handleDebounceSearch?: (val: string) => void;
  handleQuickSearch?: (val: string) => void;
  handleSelectionActions?: (
    name: string,
    selectionModel: GridSelectionModel
  ) => void;
  isSearchable?: boolean;
  handleCustomFilterButton?: () => void;
  disableSearchField: boolean;
}

const CustomToolbar = (props: ICustomToolbar) => {
  const {
    title,
    selectionModel,
    actionList,
    quickSearchValue,
    handleQuickSearch,
    handleDebounceSearch,
    handleSelectionActions,
    isSearchable,
    handleCustomFilterButton,
    disableSearchField,
  } = props || {};
  const [searchValue, setSearchValue] = useState<string>(
    quickSearchValue || ""
  );
  const isSelectionModel = selectionModel?.length;

  const handleChange = (e: React.ChangeEvent<any>) => {
    setSearchValue(e.target.value);
    handleDebounceSearch && handleDebounceSearch(e.target.value);
  };

  const handleClear = () => {
    setSearchValue("");
    handleQuickSearch && handleQuickSearch("");
  };

  return (
    <Container
      className={`${styles.tableHeaderBarContainer} ${
        isSelectionModel && styles.selectionBg
      }`}
    >
      <TypoGraph variant="h2" mb={0} align="left">
        {title}
      </TypoGraph>
      <GridToolbarContainer className={styles.filterBarContainer}>
        {isSelectionModel ? (
          <Container className={styles.rowSelectionContainer}>
            <TypoGraph
              color={colors.primary}
            >{`${isSelectionModel} rows selected`}</TypoGraph>
            {actionList?.map(
              (item, index) =>
                item?.selection !== false && (
                  <Icon
                    key={`key-${index}`}
                    name={item?.icon}
                    size={item?.iconSize || 30}
                    onClick={() =>
                      handleSelectionActions &&
                      handleSelectionActions(item?.name, selectionModel)
                    }
                    iconButton
                  />
                )
            )}
          </Container>
        ) : (
          <>
            {isSearchable && (
              <TextField
                id={"SearchField"}
                label=""
                variant="standard"
                placeholder="Search..."
                className={styles.searchField}
                value={searchValue}
                startAdornment={<SearchIcon />}
                endAdornment={
                  searchValue && (
                    <Icon size={15} onClick={handleClear}>
                      <ClearIcon />
                    </Icon>
                  )
                }
                onChange={handleChange}
                disabled={disableSearchField}
                autoFocus={Boolean(quickSearchValue)}
              />
            )}
            {handleCustomFilterButton && (
              <Icon
                hover={true}
                boxClassName={styles.filterBarButton}
                onClick={handleCustomFilterButton}
                name={"icon_company_filter"}
              />
            )}
            <GridToolbarColumnsButton
              startIcon={<Icon name="icon_column" size={15} />}
              sx={{ fontSize: "0px" }}
              color={"neutral"}
              className={styles.filterBarButton}
            />
            <GridToolbarDensitySelector
              sx={{ fontSize: "0px" }}
              color={"neutral"}
              className={styles.filterBarButton}
              id="density"
            />
            <GridToolbarExport
              sx={{ fontSize: "0px" }}
              color={"neutral"}
              className={styles.filterBarButton}
            />
          </>
        )}
      </GridToolbarContainer>
    </Container>
  );
};

const EmptyResultComponent = () => {
  return (
    <Container className={styles.emptyResultContainer}>
      <TypoGraph content="No Data Found" />
    </Container>
  );
};

const TableComponent = (props: ITableProps) => {
  const {
    loading,
    searchValue,
    data,
    totalCount,
    columns,
    currentPageSize,
    title = "",
    defaultPageSize = 10,
    checkboxSelection = false,
    actionList,
    disableActions,
    density = "standard",
    handleQuickSearch,
    handleActions,
    handleSelectionActions,
    handleCheckboxSelection,
    handlePagination,
    isRowSelectable = () => true,
    serverPaginationMode = true,
    handleCustomFilterButton,
    serverSortingMode = true,
    isSearchable = true,
    handleSortModelChange,
    onRowClick,
    columnVisibilityModel,
  } = props;
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [customColumns, setCustomColumns] = useState(() => columns);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [disableSearchField, setDisableSearchField] = useState<boolean>(false);

  const windowHeight = window.innerHeight;

  /*
   * function to build list of action cells
   * {param} id => will contain the row ID and will be passed to handleActions
   * ${return} => list of action array
   */
  const buildActionData = useCallback(
    (id: number) => {
      const actionItems = actionList?.map((item) => {
        if (disableActions?.includes(id)) {
          return <></>;
        }
        return (
          <GridActionsCellItem
            id={item.id}
            icon={<Icon name={item?.icon} size={item?.iconSize || 25} />}
            label={item?.name}
            className={`actionButton ${item?.className}`}
            onClick={() => handleActions && handleActions(id, item?.name)}
            color="inherit"
          />
        );
      });
      return actionItems;
    },
    [actionList, disableActions, handleActions]
  );

  /**
   * This effect is used to merge the action object with columns
   * This logic will only if we list of actions from the props
   */
  useEffect(() => {
    const action = {
      field: "actions",
      type: "actions",
      width: 0,
      cellClassName: "actions",
      getActions: ({ id }: { id: number }) => buildActionData(id),
    };
    const actionsLength = actionList?.length;
    if (actionsLength) {
      setCustomColumns([...columns, action]);
    }
  }, [actionList, columns, buildActionData]);

  useEffect(() => {
    setDisableSearchField(false);
  }, [data]);

  useEffect(() => {
    if (currentPageSize) {
      setPageSize(currentPageSize);
    }
  }, [currentPageSize]);

  const handleDebounceSearch = _.debounce((value: string) => {
    handleQuickSearch && handleQuickSearch(value);
    setDisableSearchField(true);
  }, 1000);

  if (onRowClick) {
    dataGridSx[".MuiDataGrid-cell"].cursor = "pointer";
  } else {
    dataGridSx[".MuiDataGrid-cell"].cursor = "default";
  }
  return (
    <Card
      sxContent={{ padding: 0, paddingBottom: "0!important" }}
      bordered={false}
    >
      <Box
        sx={{
          height: windowHeight - 150,
        }}
      >
        <DataGrid
          sx={dataGridSx}
          rows={data}
          columns={customColumns}
          pageSize={pageSize}
          density={density}
          onSortModelChange={handleSortModelChange}
          onPageSizeChange={(newPageSize) => {
            setPageSize(newPageSize);
            handlePagination && handlePagination(currentPage, newPageSize);
          }}
          rowsPerPageOptions={[5, 10, 20, 50, 100]}
          loading={loading}
          paginationMode={serverPaginationMode ? "server" : "client"}
          sortingMode={serverSortingMode ? "server" : "client"}
          rowCount={totalCount}
          onPageChange={(page) => {
            setCurrentPage(page);
            handlePagination && handlePagination(page, pageSize);
          }}
          components={{
            Toolbar: () => (
              <CustomToolbar
                title={title}
                selectionModel={selectionModel}
                actionList={actionList}
                quickSearchValue={searchValue}
                isSearchable={isSearchable}
                handleDebounceSearch={handleDebounceSearch}
                handleQuickSearch={handleQuickSearch}
                handleSelectionActions={handleSelectionActions}
                handleCustomFilterButton={handleCustomFilterButton}
                disableSearchField={disableSearchField}
              />
            ),
            NoRowsOverlay: EmptyResultComponent,
          }}
          checkboxSelection={checkboxSelection}
          onSelectionModelChange={(newSelectionModel: GridSelectionModel) => {
            setSelectionModel(newSelectionModel);
            handleCheckboxSelection &&
              handleCheckboxSelection(newSelectionModel);
          }}
          isRowSelectable={isRowSelectable}
          selectionModel={selectionModel}
          disableColumnFilter
          disableSelectionOnClick
          getRowClassName={(params) => `theme--${params.row?.status}`}
          onRowClick={(data) => (onRowClick ? onRowClick(data.row) : null)}
          columnVisibilityModel={columnVisibilityModel}
        />
      </Box>
    </Card>
  );
};

export default TableComponent;
