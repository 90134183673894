import { gridClasses, GridRenderCellParams } from "@mui/x-data-grid";
import { Chip, Container, TypoGraph } from "components";
import { ITabData } from "components/tabs";
import {
  IAdminLogResponse,
  IBillingAccountInvoice,
  IBillingAccountResponse,
  ICompanyAccountResponse,
  ICompanyRequest,
  IDisbursementAccountResponse,
  INewBillingAccountRequest,
  IPayment,
} from "interfaces";
import currencyFormatter from "tools/currencyFormatter";
import colors from "theme/colors";
import strings from "strings";
import {
  GridAlignment,
  GridColDef,
} from "@mui/x-data-grid/models/colDef/gridColDef";
import { Link } from "react-router-dom";
import { CompaniesCellRenderer } from "pages/users/partial/companyCellRenderer";
import { IWhitelabelConfigResponse } from "interfaces/IWhitelabelConfigurationRequest";

const dataGridSx = {
  ".MuiDataGrid-row": {
    maxHeight: "none!important",
  },
  ".MuiDataGrid-cell": {
    maxHeight: "none!important",
    justifyContent: "space-between",
    cursor: "default",
  },
  ".MuiDataGrid-columnHeaderTitle": {
    fontWeight: 700,
  },
  ".MuiDataGrid-columnSeparator--sideRight": {
    display: "none",
  },
  ".MuiDataGrid-columnHeaderDraggableContainer": {
    width: "auto",
  },
  ".MuiDataGrid-cell:focus-within": {
    outline: "none",
  },
  "& .actionButton": {
    display: "none",
  },
  ".MuiDataGrid-actionsCell": {
    position: "absolute",
    right: "2rem",
  },
  [`& .${gridClasses.row}:hover`]: {
    ".actionButton": {
      display: "block",
    },
  },
  ".MuiDataGrid-cell:focus": {
    outline: "none",
  },
  ".MuiDataGrid-columnHeader:focus-within": {
    outline: "none",
  },
  ".MuiDataGrid-selectedRowCount ": {
    visibility: "hidden",
  },
  background: "transparent",
  "& .theme--limithold": {
    backgroundColor: colors.held,
  },
};

const { DISBURSEMENT_STRING: disbursements } = strings;
const { MULTIPLE_RFP_STRING: multipleRFP } = strings;
const DEFAULT_DATE = "1970-01-08";

const paymentsList = {
  paymentsColumns: [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      hideable: false,
      handleClick: (_val: number | string) => {},
      renderCell: function (params: GridRenderCellParams) {
        return (
          <TypoGraph
            variant="body1"
            color={colors.primary}
            onClick={() => this.handleClick(params.value)}
            link
          >
            {params.value}
          </TypoGraph>
        );
      },
    },
    {
      field: "agent",
      headerName: "Payor",
      width: 160,
    },
    {
      field: "property",
      headerName: "Property",
      width: 250,
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 140,
      renderCell: (params: GridRenderCellParams) =>
        currencyFormatter(params.value),
    },
    {
      field: "createdAt",
      headerName: "Created",
      width: 150,
    },
    {
      field: "due",
      headerName: "Due",
      width: 90,
    },
    {
      field: "method",
      headerName: "Method",
      sorting: false,
      width: 150,
    },
  ],
  actionList: [
    {
      name: "reject",
      icon: "icon_reject",
    },
    {
      name: "hold",
      icon: "icon_hold",
    },
    {
      name: "deposit",
      icon: "icon_deposit",
    },
  ],
};

export const disbursementTableList = {
  details: [
    {
      name: "id",
      label: "ID",
      dataTypoProps: {
        color: colors.primary,
      },
    },
    {
      name: "payingUserName",
      label: "Creator",
    },
    {
      name: "recipientFirstName",
      label: "Recipient First Name",
    },
    {
      name: "recipientLastName",
      label: "Recipient Last Name",
    },
    {
      name: "recipientEmail",
      label: "Recipient Email",
    },
    {
      name: "amount",
      label: "Amount",
    },
    {
      name: "created",
      label: "Created",
    },
    {
      name: "disbursementAccountName",
      label: "Account",
    },
    {
      name: "paymentMethodName",
      label: "Payment Method",
    },
    {
      name: "checkNumber",
      label: "Check Number",
    },
    {
      name: "memo",
      label: "Memo",
    },
    {
      name: "disbursementStatusName",
      label: "Status",
    },
  ],
  reviewedBy: [
    {
      name: "reviewerUserName",
      label: "Reviewer User",
    },
    {
      name: "reviewerUserId",
      label: "Reviewer User ID",
    },
  ],
  columns: [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      hideable: false,
      handleClick: (_val: number | string) => {},
      renderCell: function (params: GridRenderCellParams) {
        return (
          <TypoGraph
            variant="body1"
            color={colors.primary}
            onClick={() => this.handleClick(params.value)}
          >
            {params.value}
          </TypoGraph>
        );
      },
    },
    {
      field: "payingUserName",
      headerName: "Creator",
      sortable: false,
      width: 220,
    },
    {
      field: "recipientFirstName",
      headerName: "Recipient",
      width: 200,
      renderCell: function (params: GridRenderCellParams) {
        return (
          <TypoGraph variant="body2">
            {(params?.value).concat(" " + params?.row?.recipientLastName)}
          </TypoGraph>
        );
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 170,
      headerAlign: "right" as GridAlignment,
      headerClassName: "MuiDataGrid-cell--textRight",
      renderCell: function (params: GridRenderCellParams) {
        return (
          <Container>
            <TypoGraph
              variant="body2"
              align="right"
              content={currencyFormatter(params.value)}
            />
          </Container>
        );
      },
    },
    {
      field: "created",
      headerName: "Created",
      width: 220,
    },
    {
      field: "scheduledDate",
      headerName: "Scheduled Payment Date",
      sortable: false,
      width: 220,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <TypoGraph variant="body2">
            {params?.value ? params.value : params?.row?.created}
          </TypoGraph>
        );
      },
    },
    {
      field: "paymentSentDate",
      headerName: "Payment Sent Date",
      renderCell: (params: GridRenderCellParams) => {
        return (
          <TypoGraph variant="body2">
            {params?.value
              ? params.value
              : strings.THIS_PAYMENT_STILL_NOT_PROCESSED}
          </TypoGraph>
        );
      },
      sortable: false,
      width: 220,
    },
    {
      field: "disbursementAccountName",
      headerName: "Account",
      sorting: false,
      width: 170,
    },
    {
      field: "paymentMethodName",
      headerName: "Payment Method",
      sorting: false,
      width: 170,
    },
  ],
  tabFilters: [
    {
      label: "Draft",
      filterValue: "DRAFT",
    },
    {
      label: "Pending Approval",
      filterValue: "PENDING_APPROVAL",
    },
    {
      label: "Scheduled",
      filterValue: "SCHEDULED",
    },
    {
      label: "Sent",
      filterValue: "DEPOSITED",
    },
    {
      label: "Failed",
      filterValue: "FAILED",
    },
    {
      label: "Rejected",
      filterValue: "REJECTED",
    },
    {
      label: "Voided",
      filterValue: "VOIDED",
    },
    {
      label: "All",
      filterValue: "ALL",
    },
  ],
};

const allCompaniesTableColumns = [
  {
    field: "id",
    headerName: "ID",
    width: 90,
    renderCell: function (params: GridRenderCellParams) {
      return (
        <TypoGraph
          id={params.value}
          variant="body1"
          color={colors.primary}
          link
        >
          {params.value}
        </TypoGraph>
      );
    },
  },
  {
    field: "name",
    headerName: "Name",
    width: 250,
  },
  {
    field: "city",
    headerName: "City",
    width: 110,
  },
  {
    field: "state",
    headerName: "State",
    width: 110,
  },
  {
    field: "pcode",
    headerName: "Postal code",
    width: 180,
  },
  {
    field: "brokerCode",
    headerName: "Broker Code",
    width: 180,
  },
  {
    field: "deleted",
    headerName: "Status",
    width: 200,
    renderCell: function (params: GridRenderCellParams) {
      return (
        <Container
          sx={{
            display: "block",
          }}
        >
          {params.row.deleted ? (
            <Chip label={"Deleted"} color={"error"} className={"errorChip"} />
          ) : (
            <Chip label={"Active"} color={"success"} className="successChip" />
          )}
        </Container>
      );
    },
  },
];

const usersList = {
  userColumns: [
    {
      field: "id",
      headerName: "ID",
      width: 100,
      hideable: false,
      handleClick: (_val: number | string, _rowData: ICompanyRequest) => {},
      renderCell: function (params: GridRenderCellParams) {
        return (
          <TypoGraph
            id={params.value}
            variant="body1"
            color={colors.primary}
            onClick={() => this.handleClick(params.value, params.row)}
            link
          >
            {params.value}
          </TypoGraph>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      width: 300,
    },
    {
      field: "firstname",
      headerName: "First Name",
      width: 150,
    },
    {
      field: "lastname",
      headerName: "Last Name",
      width: 150,
    },
    {
      field: "priv",
      headerName: "Role",
      width: 180,
    },
    {
      field: "adminCompanies",
      headerName: "Companies",
      width: 200,
      sortable: false,
      renderCell: CompaniesCellRenderer,
    },
    {
      field: "active",
      headerName: "Status",
      width: 200,
      renderCell: function (params: GridRenderCellParams) {
        return (
          <Container
            sx={{
              display: "block",
            }}
          >
            {params.row.statusDescription && !params.row.deleted ? (
              <Chip
                label={params.row.statusDescription}
                color={
                  ["Active", "Invite Pending"].includes(
                    params.row.statusDescription
                  )
                    ? "success"
                    : "error"
                }
                className={
                  ["Active", "Invite Pending"].includes(
                    params.row.statusDescription
                  )
                    ? "successChip"
                    : "errorChip"
                }
              />
            ) : params.row.deleted ? (
              <Chip label={"Deleted"} color={"error"} className="errorChip" />
            ) : (
              <Chip
                label={"Active"}
                color={"success"}
                className="successChip"
              />
            )}
            {params.row.locked && (
              <Chip label={"Locked"} color={"error"} className="errorChip" />
            )}
          </Container>
        );
      },
    },
  ],

  actionList: [
    {
      id: "editTable",
      name: "Edit",
      icon: "icon_edit",
      iconSize: 17,
      className: "tableActionButton",
    },
  ],
};

const billingAccountsList = {
  billingAccountColumns: [
    {
      field: "id",
      headerName: "ID",
      width: 120,
      hideable: false,
      handleClick: (
        _val: number | string,
        _rowData: INewBillingAccountRequest
      ) => {},
      renderCell: function (params: GridRenderCellParams) {
        return (
          <TypoGraph
            id={params.value}
            variant="body1"
            color={colors.primary}
            onClick={() => this.handleClick(params.value, params.row)}
            link
          >
            {params.value}
          </TypoGraph>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      width: 300,
    },
    {
      field: "companies",
      headerName: "Companies",
      sortable: false,
      width: 300,
      renderCell: function (params: GridRenderCellParams) {
        return (
          <Container>
            {params.row?.companies.map((company: any, index: number) => {
              return (
                <Container key={index}>
                  <Link
                    to={`/settings/companies/${company.companyId}`}
                    style={{ textDecoration: "none" }}
                  >
                    <TypoGraph
                      id={company.companyName + index}
                      variant="body2"
                      color={colors.primary}
                      link
                    >
                      {company.companyName}
                    </TypoGraph>
                  </Link>
                </Container>
              );
            })}
          </Container>
        );
      },
    },
    {
      field: "cardholder",
      headerName: "Cardholder user",
      width: 200,
      renderCell: function (params: GridRenderCellParams) {
        return (
          <Container>
            <Link
              to={`/settings/users/${params.row?.cardholder.id}`}
              style={{ textDecoration: "none" }}
            >
              <TypoGraph
                id={"cardHolderEmail" + params?.row?.id}
                variant="body2"
                color={colors.primary}
                link
              >
                {params.row?.cardholder.email}
              </TypoGraph>
            </Link>
          </Container>
        );
      },
    },
  ],
};

export const paymentFilters: ITabData[] = [
  {
    label: "New",
    filterValue: "NEW",
  },
  {
    label: "Ready For Deposit",
    filterValue: "READY_FOR_DEPOSIT",
  },
  {
    label: "Requested",
    filterValue: "REQUESTED",
  },
  {
    label: "Recurring Requests",
    filterValue: "RECURRING",
  },
  {
    label: "Deposit Queue",
    filterValue: "DEPOSIT_QUEUE",
  },
  {
    label: "Submitted",
    filterValue: "SUBMITTED",
  },
  {
    label: "On Hold",
    filterValue: "ON_HOLD",
  },
  {
    label: "Rejected",
    filterValue: "REJECTED",
  },
  {
    label: "Completed",
    filterValue: "COMPLETED",
  },
  {
    label: "All",
    filterValue: "ALL",
  },
];
export const allPaymentFilters: ITabData[] = [
  {
    label: "All",
    filterValue: "ALL",
  },
  {
    label: "New",
    filterValue: "NEW",
  },
  {
    label: "Ready For Deposit",
    filterValue: "READY_FOR_DEPOSIT",
  },
  {
    label: "Requested",
    filterValue: "REQUESTED",
  },
  {
    label: "Recurring Requests",
    filterValue: "RECURRING",
  },
  {
    label: "Deposit Queue",
    filterValue: "DEPOSIT_QUEUE",
  },
  {
    label: "Submitted",
    filterValue: "SUBMITTED",
  },
  {
    label: "On Hold",
    filterValue: "ON_HOLD",
  },
  {
    label: "Rejected",
    filterValue: "REJECTED",
  },
  {
    label: "Completed",
    filterValue: "COMPLETED",
  },
];

export const receivablesRequesterTab: ITabData[] = [
  {
    label: "Requested",
    filterValue: "REQUESTED",
  },
];

export const paymentColumns = [
  {
    field: "id",
    headerName: "ID",
    width: 110,
    renderCell: function (params: GridRenderCellParams) {
      return (
        <TypoGraph
          id={params?.value}
          variant="body2"
          color={colors.primary}
          link
        >
          {params.value}
        </TypoGraph>
      );
    },
  },
  {
    field: "firstName",
    headerName: "Payor",
    width: 150,
    renderCell: function (params: GridRenderCellParams) {
      return (
        <TypoGraph
          variant="body2"
          content={`${params?.row?.lastName}, ${params?.row?.firstName}`}
        />
      );
    },
  },
  {
    field: "address",
    headerName: "Property",
    minWidth: 200,
    flex: 1,
    renderCell: function (params: GridRenderCellParams) {
      return (
        <Container>
          <TypoGraph variant="body2" content={params?.row?.address?.address1} />
          <TypoGraph
            variant="body2"
            content={`${params?.row?.address?.city} ${params?.row?.address?.state}`}
          />
        </Container>
      );
    },
  },
  {
    field: "amount",
    headerName: "Amount",
    minWidth: 130,
    flex: 0.6,
    headerAlign: "right" as GridAlignment,
    headerClassName: "MuiDataGrid-cell--textRight",
    renderCell: function (params: GridRenderCellParams) {
      return (
        <Container>
          <TypoGraph
            variant="body2"
            align="right"
            content={currencyFormatter(params.value)}
          />
        </Container>
      );
    },
  },
  {
    field: "type",
    headerName: "Payment Type",
    width: 180,
    renderCell: function (params: GridRenderCellParams) {
      return (
        <TypoGraph
          variant="body2"
          content={
            params?.row?.type === "Commission Earned"
              ? "Commission Check"
              : params?.row?.type
          }
        />
      );
    },
  },
  {
    field: "receivableStatus",
    headerName: "Status",
    width: 160,
    renderCell: function (params: GridRenderCellParams) {
      return (
        <Chip label={params.value} className={getChipClass(params.value)} />
      );
    },
  },
  {
    field: "fileNumber",
    headerName: "File Number",
    minWidth: 150,
    flex: 0.6,
  },
  {
    field: "companyName",
    headerName: "Company",
    minWidth: 200,
    flex: 1,
  },
  {
    field: "created",
    headerName: "Created",
    minWidth: 250,
    flex: 0.6,
  },
  {
    field: "prDueDate",
    headerName: "Due Date",
    minWidth: 150,
    flex: 0.6,
    hide: false,
    renderCell: function (params: GridRenderCellParams) {
      return (
        <TypoGraph
          variant="body2"
          content={params?.row?.prDueDate ? `${params?.row?.prDueDate}` : "-"}
        />
      );
    },
  },
  {
    field: "paymentMethod",
    headerName: "Method",
    width: 170,
  },
];

function getChipClass(value: string): string {
  switch (value) {
    case "Completed":
      return "completedChip";
    case "Approved":
    case "Ready For Deposit":
      return "successChip";
    case "Rejected":
    case "Submit Failed":
    case "Print Failed":
    case "Failed":
      return "errorChip";
    case "On Hold":
    case "Limit Hold":
    case "5 Days To Deposit":
    case "4 Days To Deposit":
    case "3 Days To Deposit":
    case "2 Days To Deposit":
    case "1 Day To Deposit":
      return "limitHoldChip";
    case "Print Pending":
      return "warningChip";
    case "New":
      return "infoChip";
    case "Requested":
      return "requestedChip";
    case "Deposited":
    case "Submitted":
      return "depositedChip";
    case "Validating Funds":
      return "validatingFundsChip";
    case "Recurring":
      return "recurringChip";
    default:
      return "defaultChip";
  }
}

export const paymentRequestColumns = [
  {
    field: "id",
    headerName: "ID",
    width: 110,
    handleClick: (val: IPayment) => {},
    renderCell: function (params: GridRenderCellParams) {
      return (
        <TypoGraph
          id={params?.value}
          variant="body2"
          color={colors.primary}
          onClick={() => this.handleClick(params.row)}
          link
        >
          {params.value}
        </TypoGraph>
      );
    },
  },
  {
    field: "firstName",
    headerName: "Payor",
    width: 150,
    renderCell: function (params: GridRenderCellParams) {
      return (
        <TypoGraph
          variant="body2"
          content={`${params?.row?.lastName}, ${params?.row?.firstName}`}
        />
      );
    },
  },
  {
    field: "address",
    headerName: "Property",
    minWidth: 200,
    flex: 1,
    renderCell: function (params: GridRenderCellParams) {
      return (
        <Container>
          <TypoGraph variant="body2" content={params?.row?.address?.address1} />
          <TypoGraph
            variant="body2"
            content={`${params?.row?.address?.city} ${params?.row?.address?.state}`}
          />
        </Container>
      );
    },
  },
  {
    field: "amount",
    headerName: "Amount",
    minWidth: 130,
    flex: 0.6,
    headerAlign: "right" as GridAlignment,
    headerClassName: "MuiDataGrid-cell--textRight",
    renderCell: function (params: GridRenderCellParams) {
      return (
        <Container>
          <TypoGraph
            variant="body2"
            align="right"
            content={currencyFormatter(params.value)}
          />
        </Container>
      );
    },
  },
  {
    field: "fileNumber",
    headerName: "File Number",
    minWidth: 100,
    flex: 0.6,
  },
  {
    field: "created",
    headerName: "Created",
    minWidth: 250,
    flex: 0.6,
  },
  {
    field: "prDueDate",
    headerName: "Due Date",
    minWidth: 150,
    flex: 0.6,
    hide: false,
    renderCell: function (params: GridRenderCellParams) {
      return (
        <TypoGraph
          variant="body2"
          content={params?.row?.prDueDate ? `${params?.row?.prDueDate}` : "-"}
        />
      );
    },
  },
];

export const disbursementAccountColumns = [
  {
    field: "id",
    headerName: "Id",
    width: 100,
    handleClick: (val: IDisbursementAccountResponse) => {},
    renderCell: function (params: GridRenderCellParams) {
      return (
        <TypoGraph
          id={params?.value}
          variant="body2"
          color={colors.primary}
          onClick={() => this.handleClick(params.row)}
          link
        >
          {params.value}
        </TypoGraph>
      );
    },
  },
  {
    field: "name",
    headerName: "Nickname",
    width: 200,
  },
  {
    field: "company",
    headerName: "Company",
    width: 200,
  },
  {
    field: "accountNumber",
    headerName: "Account Ending In",
    sortable: false,
    width: 160,
  },
  {
    field: "accountType",
    headerName: "Account Type",
    sortable: false,
    width: 150,
    hide: false,
  },
  {
    field: "financialInstitution",
    headerName: "Financial Institution",
    sortable: false,
    width: 160,
  },
  {
    field: "verificationStatus",
    headerName: "Verification Status",
    sortable: false,
    width: 160,
  },
  {
    field: "approvalRequired",
    headerName: "Requires Approval",
    sortable: false,
    width: 160,
    renderCell: function (params: GridRenderCellParams) {
      return (
        <Chip
          label={params.value ? "Required" : "Not Required"}
          color={params.value ? "success" : "error"}
          className={params.value ? "successChip" : "errorChip"}
        />
      );
    },
  },
  {
    field: "dualSignatureRequired",
    headerName: "Dual Signature",
    sortable: false,
    width: 160,
    renderCell: function (params: GridRenderCellParams) {
      return (
        <Chip
          label={params.value ? "Required" : "Not Required"}
          color={params.value ? "success" : "error"}
          className={params.value ? "successChip" : "errorChip"}
        />
      );
    },
  },
  {
    field: "hasSignature",
    headerName: "Signature Status",
    sortable: false,
    width: 160,
    renderCell: function (params: GridRenderCellParams) {
      return (
        <Chip
          label={params.value ? "Saved" : "Not Saved"}
          color={params.value ? "success" : "error"}
          className={params.value ? "successChip" : "errorChip"}
        />
      );
    },
  },
];

const paymentRequestDetailsColumn = {
  requestDetails: {
    0: [
      {
        name: "id",
        label: "ID",
      },
      {
        name: "created",
        label: "Created",
      },
      {
        name: "requesterUserName",
        label: "Requestor",
      },
    ],
  },
  companyDetails: {
    0: [
      {
        name: "companyName",
        label: "Company",
      },
    ],
  },
  payerDetails: {
    0: [
      {
        name: "agentName",
        label: "Payer",
      },
      {
        name: "buyerEmail",
        label: "Email Address",
      },
      {
        name: "buyerPhone",
        label: "Phone",
      },
      {
        name: "fileNumber",
        label: "File number",
      },
    ],
  },
  propertyDetails: {
    0: [
      {
        name: "addressMask",
        label: "Property",
      },
    ],
  },
  transactionDetails: {
    0: [
      {
        name: "agentName",
        label: "Agent Name",
      },
      {
        name: "amount",
        label: "Amount",
        dataTypoProps: {
          color: colors.primary,
        },
      },
    ],
  },
  recurringDetails: {
    0: [
      {
        name: "recurringPaymentStartDate",
        label: "Recurring Request start date",
      },
      { name: "recurringPaymentEndDate", label: "Recurring Request end date" },
      { name: "recurringPaymentLastPaymentSent", label: "Last Request sent" },
      {
        name: "recurringPaymentNextPaymentSent",
        label: "Next Request to be sent on",
      },
      {
        name: "recurringPaymentFrequency",
        label: "Frequency",
        dataTypoProps: {
          sx: { maxWidth: "390px !important", textAlign: "end" },
        },
      },
    ],
  },
};
const paymentDetailsColumn = {
  details: {
    0: [
      {
        name: "amount",
        label: "Amount",
        dataTypoProps: {
          color: colors.primary,
        },
      },
      {
        name: "statusMask",
        label: "Status",
      },
      {
        name: "created",
        label: "Created",
      },
      {
        name: "updated",
        label: "Updated",
      },
      {
        name: "agentName",
        label: "Agent Name",
        hide: function (params: any) {
          return Number(params.role) === 0;
        },
      },
      {
        name: "saname",
        label: "Buyer Name",
        hide: function (params: any) {
          return Number(params.role) === 0;
        },
      },
      {
        name: "saname",
        label: "Agent Name",
        hide: function (params: any) {
          return Number(params.role) !== 0;
        },
      },
      {
        name: "agentName",
        label: "Buyer Name",
        hide: function (params: any) {
          return Number(params.role) !== 0;
        },
      },
      {
        name: "fileNumber",
        label: "File number",
      },
      {
        name: "createdBy",
        label: "Creator",
      },
      {
        name: "roleName",
        label: "Role",
      },
      {
        name: "type",
        label: "Type",
      },
      {
        name: "paymentMethod",
        label: "Payment Method",
      },
      {
        name: "buyerEmail",
        label: "Buyer email",
        hide: function (params: any) {
          return Number(params.role) === 0;
        },
      },
      {
        name: "buyerPhone",
        label: "Buyer phone",
        hide: function (params: any) {
          return Number(params.role) === 0;
        },
      },
      {
        name: "buyerEmail",
        label: "Agent email",
        hide: function (params: any) {
          return Number(params.role) !== 0;
        },
      },
      {
        name: "buyerPhone",
        label: "Agent phone",
        hide: function (params: any) {
          return Number(params.role) !== 0;
        },
      },
      {
        name: "laname",
        label: "Listing Agent Name",
        hide: function (params: any) {
          return params.role != 2;
        },
      },
      {
        name: "laname",
        label: "Selling Agent name",
        hide: function (params: any) {
          return params.role != 3;
        },
      },
      {
        name: "specialinst",
        label: "Special instructions",
      },
      {
        name: "titleCoContact",
        label: "Title Co. Contact",
      },
    ],
    1: [
      {
        name: "amount",
        label: "Amount",
        dataTypoProps: {
          color: colors.primary,
        },
      },
      {
        name: "statusMask",
        label: "Status",
      },

      {
        name: "created",
        label: "Created",
      },
      {
        name: "updated",
        label: "Updated",
      },
      {
        name: "agentName",
        label: "Agent Name",
      },
      {
        name: "fileNumber",
        label: "File number",
      },
      {
        name: "createdBy",
        label: "Creator",
      },
      {
        name: "roleName",
        label: "Role",
      },
      {
        name: "type",
        label: "Type",
      },
      {
        name: "paymentMethod",
        label: "Payment Method",
      },
      {
        name: "name1",
        label: "Tenant 1",
      },
      {
        name: "name2",
        label: "Tenant 2",
      },
      {
        name: "name3",
        label: "Landlord/Owner 1",
      },
      {
        name: "name4",
        label: "Landlord/Owner 2",
      },
      {
        name: "laname",
        label: "Listing Agent Name",
      },
      {
        name: "saname",
        label: "Selling Agent name",
      },
      {
        name: "specialinst",
        label: "Special instructions",
      },
    ],
    2: [
      {
        name: "amount",
        label: "Amount",
        dataTypoProps: {
          color: colors.primary,
        },
      },
      {
        name: "statusMask",
        label: "Status",
      },

      {
        name: "created",
        label: "Created",
      },
      {
        name: "updated",
        label: "Updated",
      },
      {
        name: "agentName",
        label: "Agent Name",
      },
      {
        name: "closingDate",
        label: "Closing Date",
        hide: function (params: any) {
          return String(params.closingDate) === DEFAULT_DATE;
        },
      },
      {
        name: "fileNumber",
        label: "File number",
      },
      {
        name: "createdBy",
        label: "Creator",
      },
      {
        name: "roleName",
        label: "Role",
      },
      {
        name: "type",
        label: "Type",
      },
      {
        name: "paymentMethod",
        label: "Payment Method",
      },
      {
        name: "laname",
        label: "Listing Agent Name",
      },
      {
        name: "saname",
        label: "Selling Agent name",
      },
      {
        name: "specialinst",
        label: "Special instructions",
      },
    ],
    3: [
      {
        name: "amount",
        label: "Amount",
        dataTypoProps: {
          color: colors.primary,
        },
      },
      {
        name: "statusMask",
        label: "Status",
      },

      {
        name: "created",
        label: "Created",
      },
      {
        name: "updated",
        label: "Updated",
      },
      {
        name: "agentName",
        label: "Agent Name",
      },
      {
        name: "fileNumber",
        label: "File number",
      },
      {
        name: "createdBy",
        label: "Creator",
      },
      {
        name: "roleName",
        label: "Role",
      },
      {
        name: "type",
        label: "Type",
      },
      {
        name: "paymentMethod",
        label: "Payment Method",
      },
      {
        name: "laname",
        label: "Listing Agent Name",
      },
      {
        name: "saname",
        label: "Selling Agent name",
      },
      {
        name: "specialinst",
        label: "Special instructions",
      },
    ],
    4: [
      {
        name: "amount",
        label: "Amount",
        dataTypoProps: {
          color: colors.primary,
        },
      },
      {
        name: "statusMask",
        label: "Status",
      },

      {
        name: "created",
        label: "Created",
      },
      {
        name: "updated",
        label: "Updated",
      },
      {
        name: "agentName",
        label: "Agent Name",
      },
      {
        name: "fileNumber",
        label: "File number",
      },
      {
        name: "createdBy",
        label: "Creator",
      },
      {
        name: "roleName",
        label: "Role",
      },
      {
        name: "type",
        label: "Type",
      },
      {
        name: "paymentMethod",
        label: "Payment Method",
      },
      {
        name: "name1",
        label: "Selling Agent",
      },
      {
        name: "name2",
        label: "Selling Phone number",
      },
      {
        name: "name3",
        label: "Selling Broker",
      },
      {
        name: "name4",
        label: "Selling Broker number",
      },
      {
        name: "laname",
        label: "Buyer 1",
      },
      {
        name: "saname",
        label: "Buyer 2",
      },
      {
        name: "specialinst",
        label: "Special instructions",
      },
    ],
    5: [
      {
        name: "amount",
        label: "Amount",
        dataTypoProps: {
          color: colors.primary,
        },
      },
      {
        name: "statusMask",
        label: "Status",
      },

      {
        name: "created",
        label: "Created",
      },
      {
        name: "updated",
        label: "Updated",
      },
      {
        name: "agentName",
        label: "Agent Name",
      },
      {
        name: "fileNumber",
        label: "File number",
      },
      {
        name: "type",
        label: "Type",
      },
      {
        name: "paymentMethod",
        label: "Payment Method",
      },
      {
        name: "specialinst",
        label: "Special instructions",
      },
      {
        name: "otherPType",
        label: "Payment Description",
      },
    ],
    6: [
      {
        name: "amount",
        label: "Amount",
        dataTypoProps: {
          color: colors.primary,
        },
      },
      {
        name: "statusMask",
        label: "Status",
      },

      {
        name: "created",
        label: "Created",
      },
      {
        name: "updated",
        label: "Updated",
      },
      {
        name: "agentName",
        label: "Agent Name",
      },
      {
        name: "fileNumber",
        label: "File number",
      },
      {
        name: "createdBy",
        label: "Creator",
      },
      {
        name: "roleName",
        label: "Role",
      },
      {
        name: "type",
        label: "Type",
      },
      {
        name: "paymentMethod",
        label: "Payment Method",
      },
      {
        name: "laname",
        label: "Listing Agent Name",
      },
      {
        name: "saname",
        label: "Selling Agent name",
      },
      {
        name: "specialinst",
        label: "Special instructions",
      },
    ],
    7: [
      {
        name: "amount",
        label: "Amount",
        dataTypoProps: {
          color: colors.primary,
        },
      },
      {
        name: "statusMask",
        label: "Status",
      },

      {
        name: "created",
        label: "Created",
      },
      {
        name: "updated",
        label: "Updated",
      },
      {
        name: "agentName",
        label: "Agent Name",
        hide: function (params: any) {
          return Number(params.role) === 0;
        },
      },
      {
        name: "saname",
        label: "Buyer Name",
        hide: function (params: any) {
          return Number(params.role) === 0;
        },
      },
      {
        name: "saname",
        label: "Agent Name",
        hide: function (params: any) {
          return Number(params.role) !== 0;
        },
      },
      {
        name: "agentName",
        label: "Buyer Name",
        hide: function (params: any) {
          return Number(params.role) !== 0;
        },
      },
      {
        name: "fileNumber",
        label: "File number",
      },
      {
        name: "createdBy",
        label: "Creator",
      },
      {
        name: "roleName",
        label: "Role",
      },
      {
        name: "type",
        label: "Type",
      },
      {
        name: "paymentMethod",
        label: "Payment Method",
      },
      {
        name: "buyerEmail",
        label: "Buyer email",
        hide: function (params: any) {
          return Number(params.role) === 0;
        },
      },
      {
        name: "buyerPhone",
        label: "Buyer phone",
        hide: function (params: any) {
          return Number(params.role) === 0;
        },
      },
      {
        name: "buyerEmail",
        label: "Agent email",
        hide: function (params: any) {
          return Number(params.role) !== 0;
        },
      },
      {
        name: "buyerPhone",
        label: "Agent phone",
        hide: function (params: any) {
          return Number(params.role) !== 0;
        },
      },
      {
        name: "laname",
        label: "Listing Agent Name",
        hide: function (params: any) {
          return params.role != 2;
        },
      },
      {
        name: "laname",
        label: "Selling Agent name",
        hide: function (params: any) {
          return params.role != 3;
        },
      },
      {
        name: "specialinst",
        label: "Special instructions",
      },
      {
        name: "titleCoContact",
        label: "Title Co. Contact",
      },
    ],
    8: [
      {
        name: "amount",
        label: "Amount",
        dataTypoProps: {
          color: colors.primary,
        },
      },
      {
        name: "statusMask",
        label: "Status",
      },

      {
        name: "created",
        label: "Created",
      },
      {
        name: "updated",
        label: "Updated",
      },
      {
        name: "agentName",
        label: "Agent Name",
        hide: function (params: any) {
          return Number(params.role) === 0;
        },
      },
      {
        name: "saname",
        label: "Buyer Name",
        hide: function (params: any) {
          return Number(params.role) === 0;
        },
      },
      {
        name: "saname",
        label: "Agent Name",
        hide: function (params: any) {
          return Number(params.role) !== 0;
        },
      },
      {
        name: "agentName",
        label: "Buyer Name",
        hide: function (params: any) {
          return Number(params.role) !== 0;
        },
      },
      {
        name: "fileNumber",
        label: "File number",
      },
      {
        name: "createdBy",
        label: "Creator",
      },
      {
        name: "roleName",
        label: "Role",
      },
      {
        name: "type",
        label: "Type",
      },
      {
        name: "paymentMethod",
        label: "Payment Method",
      },
      {
        name: "buyerEmail",
        label: "Buyer email",
        hide: function (params: any) {
          return Number(params.role) === 0;
        },
      },
      {
        name: "buyerPhone",
        label: "Buyer phone",
        hide: function (params: any) {
          return Number(params.role) === 0;
        },
      },
      {
        name: "buyerEmail",
        label: "Agent email",
        hide: function (params: any) {
          return Number(params.role) !== 0;
        },
      },
      {
        name: "buyerPhone",
        label: "Agent phone",
        hide: function (params: any) {
          return Number(params.role) !== 0;
        },
      },
      {
        name: "laname",
        label: "Listing Agent Name",
        hide: function (params: any) {
          return params.role != 2;
        },
      },
      {
        name: "laname",
        label: "Selling Agent name",
        hide: function (params: any) {
          return params.role != 3;
        },
      },
      {
        name: "specialinst",
        label: "Special instructions",
      },
      {
        name: "titleCoContact",
        label: "Title Co. Contact",
      },
    ],
    9: [
      {
        name: "amount",
        label: "Amount",
        dataTypoProps: {
          color: colors.primary,
        },
      },
      {
        name: "statusMask",
        label: "Status",
      },

      {
        name: "created",
        label: "Created",
      },
      {
        name: "updated",
        label: "Updated",
      },
      {
        name: "agentName",
        label: "Agent Name",
        hide: function (params: any) {
          return Number(params.role) === 0;
        },
      },
      {
        name: "saname",
        label: "Buyer Name",
        hide: function (params: any) {
          return Number(params.role) === 0;
        },
      },
      {
        name: "saname",
        label: "Agent Name",
        hide: function (params: any) {
          return Number(params.role) !== 0;
        },
      },
      {
        name: "agentName",
        label: "Buyer Name",
        hide: function (params: any) {
          return Number(params.role) !== 0;
        },
      },
      {
        name: "fileNumber",
        label: "File number",
      },
      {
        name: "createdBy",
        label: "Creator",
      },
      {
        name: "roleName",
        label: "Role",
      },
      {
        name: "type",
        label: "Type",
      },
      {
        name: "paymentMethod",
        label: "Payment Method",
      },
      {
        name: "buyerEmail",
        label: "Buyer email",
        hide: function (params: any) {
          return Number(params.role) === 0;
        },
      },
      {
        name: "buyerPhone",
        label: "Buyer phone",
        hide: function (params: any) {
          return Number(params.role) === 0;
        },
      },
      {
        name: "buyerEmail",
        label: "Agent email",
        hide: function (params: any) {
          return Number(params.role) !== 0;
        },
      },
      {
        name: "buyerPhone",
        label: "Agent phone",
        hide: function (params: any) {
          return Number(params.role) !== 0;
        },
      },
      {
        name: "saname",
        label: "Selling Agent name",
        hide: function (params: any) {
          return params.role != 3;
        },
      },
      {
        name: "specialinst",
        label: "Special instructions",
      },
      {
        name: "titleCoContact",
        label: "Title Co. Contact",
      },
    ],
    10: [
      {
        name: "amount",
        label: "Amount",
        dataTypoProps: {
          color: colors.primary,
        },
      },
      {
        name: "statusMask",
        label: "Status",
      },

      {
        name: "created",
        label: "Created",
      },
      {
        name: "updated",
        label: "Updated",
      },
      {
        name: "agentName",
        label: "Agent Name",
        hide: function (params: any) {
          return Number(params.role) === 0;
        },
      },
      {
        name: "saname",
        label: "Buyer Name",
        hide: function (params: any) {
          return Number(params.role) === 0;
        },
      },
      {
        name: "saname",
        label: "Agent Name",
        hide: function (params: any) {
          return Number(params.role) !== 0;
        },
      },
      {
        name: "agentName",
        label: "Buyer Name",
        hide: function (params: any) {
          return Number(params.role) !== 0;
        },
      },
      {
        name: "fileNumber",
        label: "File number",
      },
      {
        name: "createdBy",
        label: "Creator",
      },
      {
        name: "roleName",
        label: "Role",
      },
      {
        name: "type",
        label: "Type",
      },
      {
        name: "paymentMethod",
        label: "Payment Method",
      },
      {
        name: "buyerEmail",
        label: "Buyer email",
        hide: function (params: any) {
          return Number(params.role) === 0;
        },
      },
      {
        name: "buyerPhone",
        label: "Buyer phone",
        hide: function (params: any) {
          return Number(params.role) === 0;
        },
      },
      {
        name: "buyerEmail",
        label: "Agent email",
        hide: function (params: any) {
          return Number(params.role) !== 0;
        },
      },
      {
        name: "buyerPhone",
        label: "Agent phone",
        hide: function (params: any) {
          return Number(params.role) !== 0;
        },
      },
      {
        name: "laname",
        label: "Listing Agent Name",
        hide: function (params: any) {
          return params.role != 2;
        },
      },
      {
        name: "laname",
        label: "Selling Agent name",
        hide: function (params: any) {
          return params.role != 3;
        },
      },
      {
        name: "specialinst",
        label: "Special instructions",
      },
      {
        name: "titleCoContact",
        label: "Title Co. Contact",
      },
    ],
    11: [
      {
        name: "amount",
        label: "Amount",
        dataTypoProps: {
          color: colors.primary,
        },
      },
      {
        name: "statusMask",
        label: "Status",
      },

      {
        name: "created",
        label: "Created",
      },
      {
        name: "updated",
        label: "Updated",
      },
      {
        name: "agentName",
        label: "Agent Name",
      },
      {
        name: "saname",
        label: "Payer Name",
      },
      {
        name: "invoiceNumber",
        label: "Invoice Number",
      },
      {
        name: "createdBy",
        label: "Creator",
      },
      {
        name: "type",
        label: "Type",
      },
      {
        name: "paymentMethod",
        label: "Payment Method",
      },
      {
        name: "buyerEmail",
        label: "Buyer email",
      },
      {
        name: "specialinst",
        label: "Special instructions",
      },
    ],
    12: [
      {
        name: "amount",
        label: "Amount",
        dataTypoProps: {
          color: colors.primary,
        },
      },
      {
        name: "statusMask",
        label: "Status",
      },

      {
        name: "created",
        label: "Created",
      },
      {
        name: "closingDate",
        label: "Close Date",
      },
      {
        name: "updated",
        label: "Updated",
      },
      {
        name: "agentName",
        label: "Agent Name",
      },
      {
        name: "crrAgentName",
        label: "CRR Agent Name",
      },
      {
        name: "mlsNumber",
        label: "MLS Number",
      },
      {
        name: "createdBy",
        label: "Creator",
      },
      {
        name: "type",
        label: "Type",
      },
      {
        name: "paymentMethod",
        label: "Payment Method",
      },
    ],
    13: [
      {
        name: "amount",
        label: "Amount",
        dataTypoProps: {
          color: colors.primary,
        },
      },
      {
        name: "coveringDate",
        label: "Covering Period",
      },
      {
        name: "agentName",
        label: "Agent Name",
      },
      {
        name: "createdBy",
        label: "Creator",
      },
      {
        name: "realEstateLicense",
        label: "Real State License #",
      },
      {
        name: "nickname",
        label: "Nickname",
      },
      {
        name: "paymentMethod",
        label: "Payment Method",
      },
    ],
    default: [
      {
        name: "amount",
        label: "Amount",
        dataTypoProps: {
          color: colors.primary,
        },
      },
      {
        name: "statusMask",
        label: "Status",
      },
      {
        name: "created",
        label: "Created",
      },
      {
        name: "updated",
        label: "Updated",
      },
      {
        name: "agentName",
        label: "Agent Name",
      },
      {
        name: "fileNumber",
        label: "File number",
      },
      {
        name: "createdBy",
        label: "Creator",
      },
      {
        name: "type",
        label: "Type",
      },
      {
        name: "paymentMethod",
        label: "Payment Method",
      },
      {
        name: "laname",
        label: "Listing Agent Name",
        hide: function (params: any) {
          return params.role != 2;
        },
      },
      {
        name: "saname",
        label: "Selling Agent name",
        hide: function (params: any) {
          return params.role != 3;
        },
      },
      {
        name: "specialinst",
        label: "Special instructions",
      },
    ],
  },
  address: [
    {
      name: "address1",
      label: "Address 1",
    },
    {
      name: "address2",
      label: "Address 2",
    },
    {
      name: "city",
      label: "City",
    },
    {
      name: "pcode",
      label: "Postal Code",
    },
  ],
  receivingCompany: [
    {
      name: "companyName",
      label: "Company Name",
    },
    {
      name: "bankName",
      label: "Bank Name",
    },
    {
      name: "fiName",
      label: "Account Name",
    },
    {
      name: "fiAccount",
      label: "Account Number",
    },
  ],
  receivableAccount: [
    {
      name: "companyName",
      label: "Company Name",
    },
    {
      name: "receivableAccountName",
      label: "Account Name",
    },
    {
      name: "receivableAccountFinancialInstitution",
      label: "Financial Institution",
    },
    {
      name: "receivableAccountNumber",
      label: "Account Number",
    },
    {
      name: "receivableAccountType",
      label: "Account Type",
    },
  ],
  payerAccountInformation: [
    {
      name: "passportHolderName",
      label: "Holder Name",
    },
    {
      name: "passportAccountNumber",
      label: "Account Number",
    },
    {
      name: "passportRoutingNumber",
      label: "Routing Number",
    },
    {
      name: "passportBankName",
      label: "Bank Name",
    },
    {
      name: "passportAccountType",
      label: "Account Type",
    },
  ],
  paymentRequestDetail: [
    {
      name: "prDueDate",
      label: "Due Date",
    },
    { name: "prFeeValue", label: "Late Fee" },
  ],
};

const allCompanyAccountsTableColumns = [
  {
    field: "id",
    headerName: "ID",
    width: 90,
    handleClick: (
      _val: number | string,
      _rowData: ICompanyAccountResponse
    ) => {},
    renderCell: function (params: GridRenderCellParams) {
      return (
        <TypoGraph
          id={params?.value}
          variant="body2"
          color={colors.primary}
          onClick={() => this.handleClick(params.value, params.row)}
          link
        >
          {params.value}
        </TypoGraph>
      );
    },
  },
  {
    field: "name",
    headerName: "Name",
    width: 180,
  },
  {
    field: "accountNo",
    headerName: "Account Number",
    width: 180,
  },
  {
    field: "paymentType",
    headerName: "Payment Type",
    width: 180,
    renderCell: function (params: GridRenderCellParams) {
      return (
        <TypoGraph
          variant="body2"
          content={
            params?.row?.paymentType === "Commission Earned"
              ? "Commission Check"
              : params?.row?.paymentType
          }
        />
      );
    },
  },
  {
    field: "processor",
    headerName: "Processor Account",
    width: 240,
  },
  {
    field: "active",
    headerName: "Status",
    width: 180,
    renderCell: function (params: GridRenderCellParams) {
      return (
        <Chip
          label={params.value ? "Active" : "Inactive"}
          color={params.value ? "success" : "error"}
          className={params.value ? "successChip" : "errorChip"}
        />
      );
    },
  },
];

const companyAccountsDetailsLabel = {
  details: [
    {
      name: "id",
      label: "ID",
      dataTypoProps: {
        color: colors.primary,
      },
    },
    {
      name: "accountNo",
      label: "Account No",
    },
    {
      name: "paymentType",
      label: "Payment Type",
    },
    {
      name: "processor",
      label: "Processor",
    },
    {
      name: "routingNumber",
      label: "Routing Number",
    },
    {
      name: "endorsement",
      label: "Endorsement Text",
    },
  ],
  depositLimit: [
    {
      name: "depDaily",
      label: "Daily Deposits Limit",
      messageIfEmpty: "No limit for daily deposits",
    },
    {
      name: "depItemLimit",
      label: "Deposited Item Limit",
      messageIfEmpty: "No limit for deposited items",
    },
    {
      name: "depBrokerDaily",
      label: "Daily Deposits Limit per Broker",
      messageIfEmpty: "No limit for daily deposits per broker",
    },
  ],
  paymentMethods: [
    {
      name: "paymentMethodsData",
      label: "Payment Methods",
    },
  ],
};

const userDetailsLabel = {
  details: [
    {
      name: "email",
      label: strings.EMAIL_ADDRESS,
      dataTypoProps: {
        color: colors.primary,
      },
    },
    {
      name: "firstname",
      label: strings.FIRST_NAME,
    },
    {
      name: "lastname",
      label: strings.LAST_NAME,
    },
    {
      name: "priv",
      label: strings.PRIVILEGE_LEVEL,
      data: "",
    },
  ],
  userCompanies: [
    {
      name: "userCompanies",
    },
  ],
  adminCompanies: [
    {
      name: "adminCompanies",
    },
  ],
};
export const userFilters: ITabData[] = [
  {
    label: "All",
    filterValue: "ALL",
  },
  {
    label: "Active",
    filterValue: "ACTIVE",
  },
  {
    label: "Inactive",
    filterValue: "INACTIVE",
  },
  {
    label: "Pending",
    filterValue: "INVITE_PENDING",
  },
  {
    label: "Expired",
    filterValue: "INVITE_EXPIRED",
  },
];
export const companyFilters: ITabData[] = [
  {
    label: "Active",
    filterValue: "ACTIVE",
  },
  {
    label: "Inactive",
    filterValue: "INACTIVE",
  },
];
const billingAccountDetailsLabel = {
  details: [
    {
      name: "id",
      label: "ID",
      dataTypoProps: {
        color: colors.primary,
      },
    },
    {
      name: "name",
      label: "Name",
    },
    {
      name: "cardholder",
      label: "Cardholder Email",
    },
  ],
  companies: [
    {
      name: "companies",
    },
  ],

  billingReportNameSummary: [
    {
      name: "billingReportNameSummary",
      label: "Product Name",
    },
  ],
  billingReportCountSummary: [
    {
      name: "billingReportCountSummary",
      label: "Count",
    },
  ],
  usageReportTableColumns: [
    {
      name: "billingProduct",
      label: "Product Name",
    },
    {
      name: "count",
      label: "Count",
    },
  ],
  stripeSubscriptionsTableColumns: [
    {
      name: "id",
      label: "Id",
    },
    {
      name: "status",
      label: "Status",
    },
  ],
};

const whiteLabelDetailsLabel = {
  details: [
    {
      name: "id",
      label: "ID",
      dataTypoProps: {
        color: colors.primary,
      },
    },
    {
      name: "subDomain",
      label: "Sub-Domain",
    },
  ],
  companies: [
    {
      name: "companies",
    },
  ],
  configJson: [
    {
      name: "backgroundColor",
      label: "Background Color",
      dataTypoProps: {
        sx: { fontWeight: "bold" },
      },
    },
    {
      name: "subtitle",
      label: "Slogan",
    },
    {
      name: "pageName",
      label: "Page name",
    },
  ],
};

const companyDetailsLabel = [
  {
    name: "name",
    label: strings.COMPANY_NAME,
    dataTypoProps: {
      color: colors.primary,
    },
  },
  {
    name: "address1",
    label: strings.ADDRESS_1,
  },
  {
    name: "brokerCode",
    label: strings.BROKER_CODE,
  },
];

const maintenanceReportColumns = [
  {
    field: "id",
    headerName: "ID",
    width: 100,
  },
  {
    field: "created",
    headerName: "Date/Admin",
    width: 200,
    renderCell: function (params: GridRenderCellParams) {
      return (
        <Container>
          <TypoGraph variant="body2">{params.row?.created}</TypoGraph>
          <Link
            to={`/settings/users/${params.row?.adminId}`}
            style={{ textDecoration: "none" }}
          >
            <TypoGraph
              id={"adminUser"}
              variant="body2"
              color={colors.primary}
              link
            >
              {params.row?.adminUser}
            </TypoGraph>
          </Link>
        </Container>
      );
    },
  },
  {
    field: "company",
    headerName: "Company",
    width: 180,
    handleClick: (_rowData: IAdminLogResponse) => {},
    renderCell: function (params: GridRenderCellParams) {
      return (
        params.row?.company && (
          <Container>
            <Link
              to={`/settings/companies/${params.row?.companyId}`}
              style={{ textDecoration: "none" }}
            >
              <TypoGraph
                id={"company"}
                variant="body2"
                color={colors.primary}
                link
              >
                {params.row?.company}
              </TypoGraph>
            </Link>
          </Container>
        )
      );
    },
  },
  {
    field: "user",
    headerName: "User/Account",
    width: 200,
    renderCell: function (params: GridRenderCellParams) {
      if (params.row?.user) {
        return (
          <Link
            to={`/settings/users/${params.row?.userId}`}
            style={{ textDecoration: "none" }}
          >
            <TypoGraph id={"user"} variant="body2" color={colors.primary} link>
              {params.row?.user}
            </TypoGraph>
          </Link>
        );
      }
      if (params.row?.account) {
        return <TypoGraph variant="body2">{params.row.account}</TypoGraph>;
      }
      if (params.row?.disbursementAccount) {
        return (
          <TypoGraph variant="body2">
            {params.row.disbursementAccount}
          </TypoGraph>
        );
      }
    },
  },
  {
    field: "fieldName",
    headerName: "Changed/Action",
    width: 180,
    renderCell: function (params: GridRenderCellParams) {
      if (params.row.fieldName) {
        return <TypoGraph variant="body2">{params.row.fieldName}</TypoGraph>;
      }

      if (params.row.action) {
        return <TypoGraph variant="body2">{params.row.action}</TypoGraph>;
      }
    },
  },
  {
    field: "previousValue",
    headerName: "From",
    width: 180,
    align: "right" as GridAlignment,
    headerClassName: "MuiDataGrid-cell--textRight",
    renderCell: function (params: GridRenderCellParams) {
      return (
        params.row.previousValue && (
          <TypoGraph variant="body2" sx={{ whiteSpace: "normal" }}>
            {params.row.previousValue}
          </TypoGraph>
        )
      );
    },
  },

  {
    field: "arrow",
    headerName: "",
    width: 5,
    renderCell: function (params: GridRenderCellParams) {
      return (
        params.row.newValue && (
          <TypoGraph variant="body2" color={colors.primary}>
            <span>{"→"}</span>
          </TypoGraph>
        )
      );
    },
  },

  {
    field: "newValue",
    headerName: "To",
    width: 250,
    renderCell: function (params: GridRenderCellParams) {
      return (
        params.row.newValue && (
          <TypoGraph variant="body2" sx={{ whiteSpace: "normal" }}>
            {params.row.newValue}
          </TypoGraph>
        )
      );
    },
  },
];

const myBillingColumns = [
  {
    field: "id",
    headerName: "ID",
    width: 120,
    handleClick: (val: IBillingAccountResponse) => {},
    renderCell: function (params: GridRenderCellParams) {
      return (
        <TypoGraph
          id={params?.value}
          variant="body1"
          color={colors.primary}
          onClick={() => this.handleClick(params.row)}
          link
        >
          {params.value}
        </TypoGraph>
      );
    },
  },
  {
    field: "name",
    headerName: "Name",
    width: 300,
  },
  {
    field: "companies",
    headerName: "Companies",
    sortable: false,
    width: 200,
    renderCell: function (params: GridRenderCellParams) {
      return (
        <Container>
          {params.row?.companies.map((company: any, index: number) => {
            return (
              <Container key={index}>
                <Link
                  to={`/settings/companies/${company.companyId}`}
                  style={{ textDecoration: "none" }}
                >
                  <TypoGraph
                    id={company?.companyName + index}
                    variant="body2"
                    color={colors.primary}
                    link
                  >
                    {company.companyName}
                  </TypoGraph>
                </Link>
              </Container>
            );
          })}
        </Container>
      );
    },
  },
];

const billingAccountInvoicesColumns = [
  {
    field: "id",
    header: "ID",
    width: 300,
    handleClick: (val: IBillingAccountInvoice) => {},
    renderCell: function (params: GridRenderCellParams) {
      return (
        <TypoGraph
          id={params?.value}
          variant="body1"
          color={colors.primary}
          onClick={() => this.handleClick(params.row)}
          link
        >
          {params.value}
        </TypoGraph>
      );
    },
  },
  {
    field: "invoiceNumber",
    headerName: "Invoice Number",
    width: 200,
  },
  {
    field: "accountName",
    headerName: "Name",
    width: 300,
  },
  { field: "created", headerName: "Created", width: 300 },

  { field: "status", headerName: "Status", width: 300 },
];

const billingAccountInvoicesDetails = [
  { name: "accountName", label: "Account Name" },
  { name: "created", label: "Created" },
  { name: "invoiceNumber", label: "Invoice Number" },
  { name: "status", label: "Status" },
  { name: "periodStart", label: "Period Start" },
  { name: "periodEnd", label: "Period End" },
];

const multipleDisbursementsExampleColumns: GridColDef[] = [
  {
    field: disbursements.DISBURSEMENT_ACCOUNT_ID,
    headerName: disbursements.DISBURSEMENT_ACCOUNT_ID,
    width: 200,
  },
  {
    field: disbursements.AMOUNT,
    headerName: disbursements.AMOUNT,
    width: 90,
    renderCell: (params: GridRenderCellParams) =>
      currencyFormatter(params.value),
  },
  {
    field: disbursements.PAYEES_EMAIL,
    headerName: disbursements.PAYEES_EMAIL,
    width: 200,
  },
  {
    field: disbursements.PAYEES_FIRST_NAME,
    headerName: disbursements.PAYEES_FIRST_NAME,
    width: 200,
  },
  {
    field: disbursements.PAYEES_LAST_NAME,
    headerName: disbursements.PAYEES_LAST_NAME,
    width: 200,
  },
  {
    field: disbursements.MEMO,
    headerName: disbursements.MEMO,
    width: 240,
  },
  {
    field: disbursements.PAYMENT_DATE,
    headerName: disbursements.PAYMENT_DATE,
    width: 150,
  },
  {
    field: disbursements.DIRECT_DEPOSIT_AS_DO,
    headerName: disbursements.DIRECT_DEPOSIT_AS_DO,
    width: 150,
  },
  {
    field: disbursements.MAIL_MY_CHECK_AS_DO,
    headerName: disbursements.MAIL_MY_CHECK_AS_DO,
    width: 150,
  },
  {
    field: disbursements.PRINT_AND_DEPOSIT_AS_DO,
    headerName: disbursements.PRINT_AND_DEPOSIT_AS_DO,
    width: 150,
  },
  {
    field: disbursements.STATUS,
    headerName: disbursements.STATUS,
    width: 120,
    renderCell: (params: GridRenderCellParams) => {
      let color: any;
      switch (params.value) {
        case "Error":
          color = "error";
          break;
        case "Inmediate":
          color = "success";
          break;
        case "Future":
          color = "primary";
          break;
        default:
          color = "error";
          break;
      }

      return (
        <Container
          sx={{
            display: "block",
          }}
        >
          <Chip label={params.value} color={color} />
        </Container>
      );
    },
  },
];

const multiplePaymentRequestsExampleColumns: GridColDef[] = [
  {
    field: multipleRFP.PAYMENT_TYPE,
    headerName: multipleRFP.PAYMENT_TYPE,
    width: 200,
  },

  {
    field: multipleRFP.AMOUNT,
    headerName: multipleRFP.AMOUNT,
    width: 90,
    renderCell: (params: GridRenderCellParams) =>
      currencyFormatter(params.value),
  },

  {
    field: multipleRFP.COMPANY_ID,
    headerName: multipleRFP.COMPANY_ID,
    width: 200,
  },

  {
    field: multipleRFP.PAYER_FIRST_NAME,
    headerName: multipleRFP.PAYER_FIRST_NAME,
    width: 200,
  },
  {
    field: multipleRFP.PAYER_LAST_NAME,
    headerName: multipleRFP.PAYER_LAST_NAME,
    width: 200,
  },
  {
    field: multipleRFP.PAYER_EMAIL_ADDRESS,
    headerName: multipleRFP.PAYER_EMAIL_ADDRESS,
    width: 200,
  },
  {
    field: multipleRFP.CHECK_CAPTURE_AS_PM,
    headerName: multipleRFP.CHECK_CAPTURE_AS_PM,
    width: 200,
  },
  {
    field: multipleRFP.SAME_DAY_ACH_AS_PM,
    headerName: multipleRFP.SAME_DAY_ACH_AS_PM,
    width: 200,
  },
];

const receivablesBankAccountsTableColumns = [
  {
    field: "id",
    headerName: "Id",
    width: 100,
    handleClick: (val: any) => {},
    renderCell: function (params: GridRenderCellParams) {
      return (
        <TypoGraph
          id={params?.value}
          variant="body2"
          color={colors.primary}
          onClick={() => this.handleClick(params.row)}
          link
        >
          {params.value}
        </TypoGraph>
      );
    },
  },
  {
    field: "accountName",
    headerName: "Account Name",
    width: 180,
  },
  {
    field: "companyName",
    headerName: "Company",
    width: 180,
  },
  {
    field: "bankAccountType",
    headerName: "Account Type",
    width: 180,
  },
  {
    field: "maskedAccountNumber",
    headerName: "Account Number",
    width: 180,
  },
  {
    field: "financialInstitution",
    headerName: "Financial Institution",
    width: 180,
  },
  {
    field: "active",
    headerName: "Status",
    width: 200,
    renderCell: function (params: GridRenderCellParams) {
      return (
        <Chip
          label={params.value ? "Active" : "Inactive"}
          color={params.value ? "success" : "error"}
          className={params.value ? "successChip" : "errorChip"}
        />
      );
    },
  },
];

export const companyAccountsFilters: ITabData[] = [
  {
    label: "Disbursement",
    filterValue: "DISBURSEMENT",
  },
  {
    label: "Receivables",
    filterValue: "RECEIVABLES",
  },
];

const dailyItemStatusReportColumns = [
  {
    field: "Item ID",
    headerName: "ID",
    width: 120,
    handleClick: (val: any) => {},
    renderCell: function (params: GridRenderCellParams) {
      return (
        <TypoGraph
          id={params?.value}
          variant="body1"
          color={colors.primary}
          onClick={() => this.handleClick(params.row)}
          link
        >
          {params.value}
        </TypoGraph>
      );
    },
  },
  {
    field: "Date Created",
    headerName: "Date Created",
    width: 180,
  },
  {
    field: "Amount",
    headerName: "Amount",
    width: 180,
    headerAlign: "right" as GridAlignment,
    headerClassName: "MuiDataGrid-cell--textRight",
    renderCell: function (params: GridRenderCellParams) {
      return (
        <Container>
          <TypoGraph variant="body2" align="right" content={params.value} />
        </Container>
      );
    },
  },
  {
    field: "Agent",
    headerName: "Agent",
    width: 180,
  },
  {
    field: "Tenant/Buyer",
    headerName: "Tenant/Buyer",
    width: 180,
  },
  {
    field: "Address",
    headerName: "Address",
    width: 180,
  },
  {
    field: "Status",
    headerName: "Status",
    width: 180,
  },
  {
    field: "Payment Type",
    headerName: "Payment Type",
    width: 180,
    renderCell: function (params: GridRenderCellParams) {
      return (
        <TypoGraph
          variant="body2"
          content={
            params?.row?.["Payment Type"] === "Commission Earned"
              ? "Commission Check"
              : params?.row?.["Payment Type"]
          }
        />
      );
    },
  },
  {
    field: "Date of Deposit",
    headerName: "Date of Deposit",
    width: 180,
  },
  {
    field: "FI Name",
    headerName: "Account",
    width: 180,
  },
  {
    field: "Reject Reason",
    headerName: "Reject Reason",
    width: 180,
  },
  {
    field: "File Number",
    headerName: "File Number",
    width: 180,
  },
  {
    field: "Company",
    headerName: "Company Name",
    width: 180,
  },
];
const dailyAgentRollupColumns = [
  {
    field: "User ID",
    headerName: "User ID",
    width: 120,
    sortable: false,
    handleClick: (val: any) => {},
    renderCell: function (params: GridRenderCellParams) {
      return (
        <TypoGraph
          id={params?.value}
          variant="body1"
          color={colors.primary}
          onClick={() => this.handleClick(params.row)}
          link
        >
          {params.value}
        </TypoGraph>
      );
    },
  },
  {
    field: "First Name",
    headerName: "First Name",
    width: 180,
  },
  {
    field: "Last Name",
    headerName: "Last Name",
    width: 180,
  },
  {
    field: "Email",
    headerName: "Email",
    width: 400,
  },
  {
    field: "Completed",
    headerName: "Completed",
    width: 180,
  },
];
const disbursementReportColumns = [
  {
    field: "id",
    headerName: "ID",
    width: 120,
    handleClick: (val: any) => {},
    renderCell: function (params: GridRenderCellParams) {
      return (
        <TypoGraph
          id={params?.value}
          variant="body1"
          color={colors.primary}
          onClick={() => this.handleClick(params.row)}
          link
        >
          {params.value}
        </TypoGraph>
      );
    },
  },
  {
    field: "Recipient",
    headerName: "Recipient",
    width: 180,
  },
  {
    field: "Amount",
    headerName: "Amount",
    width: 180,
    headerAlign: "right" as GridAlignment,
    headerClassName: "MuiDataGrid-cell--textRight",
    renderCell: function (params: GridRenderCellParams) {
      return (
        <Container>
          <TypoGraph variant="body2" align="right" content={params.value} />
        </Container>
      );
    },
  },
  {
    field: "Created",
    headerName: "Date Created",
    width: 180,
  },
  {
    field: "Account",
    headerName: "Account",
    width: 180,
  },
  {
    field: "Memo",
    headerName: "Memo",
    width: 180,
  },
  {
    field: "Check number",
    headerName: "Check #",
    width: 180,
  },
  {
    field: "Payment Sent Date",
    headerName: "Payment Sent Date",
    width: 180,
  },
  {
    field: "File Number",
    headerName: "File Number",
    width: 180,
  },
  {
    field: "Company",
    headerName: "Company Name",
    width: 180,
  },
];

const dailyDepositReportColumns = [
  {
    field: "Item ID",
    headerName: "ID",
    width: 120,
    handleClick: (val: any) => {},
    renderCell: function (params: GridRenderCellParams) {
      return (
        <TypoGraph
          id={params?.value}
          variant="body1"
          color={colors.primary}
          onClick={() => this.handleClick(params.row)}
          link
        >
          {params.value}
        </TypoGraph>
      );
    },
  },
  {
    field: "Date Created",
    headerName: "Date Created",
    width: 180,
  },
  {
    field: "Amount",
    headerName: "Amount",
    width: 180,
    headerAlign: "right" as GridAlignment,
    headerClassName: "MuiDataGrid-cell--textRight",
    renderCell: function (params: GridRenderCellParams) {
      return (
        <Container>
          <TypoGraph variant="body2" align="right" content={params.value} />
        </Container>
      );
    },
  },
  {
    field: "Agent",
    headerName: "Agent",
    width: 180,
  },
  {
    field: "Tenant/Buyer",
    headerName: "Tenant/Buyer",
    width: 180,
  },
  {
    field: "Address",
    headerName: "Address",
    width: 180,
  },
  {
    field: "Payment Type",
    headerName: "Payment Type",
    width: 180,
    renderCell: function (params: GridRenderCellParams) {
      return (
        <TypoGraph
          variant="body2"
          content={
            params?.row?.["Payment Type"] === "Commission Earned"
              ? "Commission Check"
              : params?.row?.["Payment Type"]
          }
        />
      );
    },
  },
  {
    field: "Date of Deposit",
    headerName: "Date of Deposit",
    width: 180,
  },
  {
    field: "FI Name",
    headerName: "Account",
    width: 180,
  },
  {
    field: "File Number",
    headerName: "File Number",
    width: 180,
  },
  {
    field: "Company",
    headerName: "Company Name",
    width: 180,
  },
];

const receivableAccountDetailBlade = {
  bankAccountColumns: [
    {
      name: "financialInstitution",
      label: "Financial Institution",
    },
    {
      name: "maskedAccountNumber",
      label: "Last 4 Digits",
    },
    {
      name: "bankAccountType",
      label: "Account Type",
    },
  ],

  accountLimitsColumns: [
    {
      name: "dailyDepositLimit",
      label: "Daily Deposit Limit",
      messageIfEmpty: "No limit for daily deposits",
    },
    {
      name: "depositedItemLimit",
      label: "Deposited Item Limit",
      messageIfEmpty: "No limit for deposited items",
    },
    {
      name: "dailyDepositLimitPerBroker",
      label: "Daily Deposits Limit per broker",
      messageIfEmpty: "No limit for daily deposits per broker",
    },
  ],
};

const receivableTableResponseMock = {
  requestedPageSize: 10,
  pageNumber: 0,
  totalCount: 50,
  items: [
    {
      id: 91,
      name: "Drake Realty, Inc.-Wells Fargo",
      company: "Drake Realty",
      companyId: 1,
      accountNumber: "****1235",
      accountType: "Checking Account",
      financialInstitution: "Wells Fargo",
      verificationStatus: "Verified",
      dualSignatureRequired: false,
      approvalRequired: false,
      initialCheckNumber: null,
      status: "Pending",
    },
    {
      id: 90,
      name: "Drake Realty-Wells Fargo",
      company: "Drake Realty",
      companyId: 2,
      accountNumber: "****1235",
      accountType: "Checking Account",
      financialInstitution: "Wells Fargo",
      verificationStatus: "Verified",
      dualSignatureRequired: false,
      approvalRequired: false,
      initialCheckNumber: null,
      status: "Active",
    },
    {
      id: 88,
      name: "Drake Realty-TD Bank",
      company: "Drake Realty",
      companyId: 26,
      accountNumber: "****1235",
      accountType: "Checking Account",
      financialInstitution: "TD Bank",
      verificationStatus: "Verified",
      dualSignatureRequired: false,
      approvalRequired: false,
      initialCheckNumber: null,
      status: "Deactivated",
    },
    {
      id: 87,
      name: "Drake Realty-Citibank Online",
      company: "Drake Realty",
      companyId: 26,
      accountNumber: "****1235",
      accountType: "Checking Account",
      financialInstitution: "Citibank Online",
      verificationStatus: "Verified",
      dualSignatureRequired: false,
      approvalRequired: false,
      initialCheckNumber: null,
      status: "Pending",
    },
    {
      id: 83,
      name: "Drake Realty-Wells Fargo",
      company: "Drake Realty",
      companyId: 26,
      accountNumber: "****1235",
      accountType: "Checking Account",
      financialInstitution: "Wells Fargo",
      verificationStatus: "Verified",
      dualSignatureRequired: false,
      approvalRequired: false,
      initialCheckNumber: 1,
      status: "Active",
    },
    {
      id: 81,
      name: "TX Escrow Account",
      company: "Drake Realty, Inc.",
      companyId: 1,
      accountNumber: "****8862",
      accountType: "Checking Account",
      financialInstitution: "Demo institution",
      verificationStatus: "Verified",
      dualSignatureRequired: true,
      approvalRequired: true,
      initialCheckNumber: null,
      status: "Deactivated",
    },
    {
      id: 80,
      name: "Demo OneAccount",
      company: "Drake Realty, Inc.",
      companyId: 1,
      accountNumber: "****1235",
      accountType: "Checking Account",
      financialInstitution: "Wells Fargo",
      verificationStatus: "Verified",
      dualSignatureRequired: false,
      approvalRequired: false,
      initialCheckNumber: null,
      status: "Pending",
    },
    {
      id: 79,
      name: "Demo multiAccount",
      company: "Drake Realty, Inc.",
      companyId: 1,
      accountNumber: "****1235",
      accountType: "Savings Account",
      financialInstitution: "Wells Fargo",
      verificationStatus: "Verified",
      dualSignatureRequired: false,
      approvalRequired: false,
      initialCheckNumber: null,
      status: "Active",
    },
    {
      id: 78,
      name: "Demo multiAccount",
      company: "Drake Realty, Inc.",
      companyId: 1,
      accountNumber: "****1235",
      accountType: "Checking Account",
      financialInstitution: "Wells Fargo",
      verificationStatus: "Verified",
      dualSignatureRequired: false,
      approvalRequired: false,
      initialCheckNumber: null,
      status: "Deactivated",
    },
    {
      id: 71,
      name: "Requires Approval",
      company: "Drake Realty, Inc.",
      companyId: 1,
      accountNumber: "****1235",
      accountType: "Checking Account",
      financialInstitution: "Wells Fargo",
      verificationStatus: "Verified",
      dualSignatureRequired: false,
      approvalRequired: true,
      initialCheckNumber: null,
      status: "Pending",
    },
  ],
};

const receivableAuditRespondeMock = {
  items: [
    {
      user: "User Admin",
      change: "Changed to Active",
      date: "08/10/23",
    },
    {
      user: "User Admin",
      change: "Changed to Inactive",
      date: "08/10/23",
    },
    {
      user: "User Admin",
      change: "Changed to Active",
      date: "06/10/23",
    },
    {
      user: "User Admin",
      change: "Changed to Inactive",
      date: "05/10/23",
    },
  ],
};

const paymentDepositSumaryColumns = [
  {
    name: "account",
    label: "Account",
  },
  {
    name: "amount",
    label: "Amount",
  },
  {
    name: "paymentMethod",
    label: "Payment Method",
  },
  {
    name: "notes",
    label: "Optional Note",
  },
];

const whiteLabelList = {
  whiteLabelColumns: [
    {
      field: "id",
      headerName: "Id",
      width: 120,
      hideable: false,
      handleClick: (
        _val: number | string,
        _rowData: IWhitelabelConfigResponse
      ) => {},
      renderCell: function (params: GridRenderCellParams) {
        return (
          <TypoGraph
            id={params.value}
            variant="body1"
            color={colors.primary}
            onClick={() => this.handleClick(params.value, params.row)}
            link
          >
            {params.value}
          </TypoGraph>
        );
      },
    },
    {
      field: "subDomain",
      headerName: "Sub domain",
      width: 300,
    },
    {
      field: "companies",
      headerName: "Companies",
      sortable: false,
      width: 600,
      renderCell: function (params: GridRenderCellParams) {
        return params?.row?.companies?.length > 0 ? (
          <TypoGraph
            variant={"body2"}
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {params.row?.companies
              .map((company: any) => company?.name)
              .join(", ")}
          </TypoGraph>
        ) : (
          <TypoGraph variant={"body2"}>No Associated companies</TypoGraph>
        );
      },
    },
  ],
};

export {
  dataGridSx,
  paymentsList,
  allCompaniesTableColumns,
  allCompanyAccountsTableColumns,
  paymentDetailsColumn,
  usersList,
  billingAccountsList,
  billingAccountDetailsLabel,
  userDetailsLabel,
  companyDetailsLabel,
  companyAccountsDetailsLabel,
  myBillingColumns,
  billingAccountInvoicesColumns,
  maintenanceReportColumns,
  billingAccountInvoicesDetails,
  multipleDisbursementsExampleColumns,
  paymentRequestDetailsColumn,
  receivablesBankAccountsTableColumns,
  dailyItemStatusReportColumns,
  disbursementReportColumns,
  dailyDepositReportColumns,
  dailyAgentRollupColumns,
  receivableAccountDetailBlade,
  receivableTableResponseMock,
  receivableAuditRespondeMock,
  multiplePaymentRequestsExampleColumns,
  paymentDepositSumaryColumns,
  whiteLabelList,
  whiteLabelDetailsLabel,
};
